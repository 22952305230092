import React, { useState } from "react";
import * as Yup from "yup";
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import logo from "../assets/icons/logo.svg";

import { Formik, Field, Form, FormikHelpers } from "formik";
import Button from "../components/Button";
import { Link, useNavigate } from "react-router-dom";
import FormElement from "../components/FormElements";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import { storeBulkInsert } from "../utils/storeHelper";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { handleAxiosError } from "../utils/errors";

interface Values {
    email: string;
    password: string;
    superAdmin: boolean;
}

type LoginScreenPropsType = {
    loginUser: Function;
    fetchUsers: Function;
    authReducer: any;
};

const SigninPageSchema = Yup.object().shape({
    email: Yup.string().min(1, "Too Short!").required("Required").email(),
    password: Yup.string().min(1, "Too Short!").required("Required"),
});

const SigninPage = (props: LoginScreenPropsType) => {
    const { loginUser, fetchUsers } = props;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handlelogin = async (data: any) => {
        setLoading(true);
        await loginUser(data)
            .then(async ({ status, data }: any) => {
                if (status === 200) {
                    storeBulkInsert(data);
                    toast.success("Welcome back");
                }
            })
            .then(async () => {
                await fetchUsers("", "").then(async ({ status, data }: any) => {
                    console.log(data, "datahh");
                    if (status === 200) {
                        storeBulkInsert({
                            users: data?.data,
                        });
                        navigate("/dashboard");
                    }
                });
            })
            .catch(async (error: any) => {
                handleAxiosError(error);
            })
            .finally(() => setLoading(false));
    };

    return (
        <AuthenticationLayout>
            {loading && <Loader />}
            <div className="max-w-[530px] w-full min-h-[530px] bg-white rounded-[20px]">
                <div className="py-5 px-10">
                    <img alt="" src={logo} className="mx-auto" />
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                            superAdmin: false,
                        }}
                        onSubmit={(values: Values) => {
                            handlelogin(values);
                        }}
                        validationSchema={SigninPageSchema}
                    >
                        {({ errors, touched }) => {
                            console.log(errors, "errors errors");
                            return (
                                <Form className="flex flex-col space-y-10 pt-10 text-lg xl:text-xl max-w-[350px] mx-auto">
                                    <FormElement
                                        type="email"
                                        name="email"
                                        label="Email Address"
                                        labelFor="email"
                                        placeholder="Email Address"
                                        error={
                                            errors.email && touched.email
                                                ? errors.email
                                                : null
                                        }
                                    />

                                    <FormElement
                                        type="password"
                                        name="password"
                                        label="Password"
                                        labelFor="password"
                                        placeholder="Password"
                                        isAuth
                                        error={
                                            errors.password && touched.password
                                                ? errors.password
                                                : null
                                        }
                                    />

                                    {/* <div className="flex justify-center items-center gap-x-5">
                                        <Field
                                            id="superAdmin"
                                            name="superAdmin"
                                            type="checkbox"
                                            className="scale-125 checked:bg-primary"
                                        />
                                        <p className="text-base xl:text-lg">
                                            Login as Super Admin
                                        </p>
                                    </div> */}

                                    <div className="text-center">
                                        <Button
                                            type="submit"
                                            variant="secondary"
                                            size="md"
                                        >
                                            Login
                                        </Button>
                                    </div>
                                    <p className="text-primary/60 text-center">
                                        Don’t have an account?{" "}
                                        <Link
                                            to="/sign-up"
                                            className="text-primary font-semibold"
                                        >
                                            Sign Up
                                        </Link>
                                    </p>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </AuthenticationLayout>
    );
};

const mapStateToProps = ({ authReducer }: any) => ({
    authReducer,
});

export default connect(mapStateToProps, actions)(SigninPage);
