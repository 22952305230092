import * as TYPES from "../dtos";
import store from "../store";

export const storeBulkInsert = (data: any) => {
    if (data.token) {
        store.dispatch({
            type: TYPES.USER_LOGIN,
            payload: data.token,
        });
    }

    if (data?.user) {
        store.dispatch({
            type: TYPES.SET_USER,
            payload: data?.user,
        });
    }

    if (data?.users) {
        store.dispatch({
            type: TYPES.SET_USERS,
            payload: data?.users,
        });
    }

    if (data?.debtors) {
        store.dispatch({
            type: TYPES.SET_DEBTORS,
            payload: data?.debtors,
        });
    }

    if (data?.activities) {
        store.dispatch({
            type: TYPES.SET_ACTIVITIES,
            payload: data?.activities,
        });
    }
};
