import React, { useState } from "react";
import * as Yup from "yup";
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import logo from "../assets/icons/logo.svg";

import { Formik, Field, Form, FormikHelpers } from "formik";
import Button from "../components/Button";
import { Link, useNavigate } from "react-router-dom";
import FormElement from "../components/FormElements";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import { storeBulkInsert } from "../utils/storeHelper";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { handleAxiosError } from "../utils/errors";

interface Values {
    fullName: string;
    email: string;
    password: string;
    confirmPassword: string;
    role: boolean;
}

type LoginScreenPropsType = {
    registerUser: Function;
    sendVerificationMail: Function;
    authReducer: any;
};

const SigninPageSchema = Yup.object().shape({
    email: Yup.string().min(1, "Too Short!").required("Required").email(),
    password: Yup.string().min(1, "Too Short!").required("Required"),
    fullName: Yup.string().min(1, "Too Short!").required("Required"),
    confirmPassword: Yup.string().min(1, "Too Short!").required("Required"),
});

const SigninPage = (props: LoginScreenPropsType) => {
    const { registerUser, sendVerificationMail } = props;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSignup = async (data: any) => {
        setLoading(true);
        let email = data.email;
        let fullName = data.fullName;

        const payload = {
            email: email,
        };
        await registerUser({
            ...data,
            role: data.role === true ? "SUPERADMIN" : "ADMIN",
        })
            .then(async ({ status }: any) => {
                if (status === 201) {
                    toast.success("Welcome, admin created");
                }
            })
            .then(async () => {
                await sendVerificationMail(payload).then(
                    async ({ status }: any) => {
                        if (status === 200) {
                            navigate("/verify-email", {
                                state: {
                                    fullName,
                                    email,
                                },
                            });
                        }
                    }
                );
            })
            .catch(async (error: any) => {
                if (error.response.data.error.code === 11000) {
                    toast.error("Email address has already been taken");
                    return
                } 
                handleAxiosError(error)
            })
            .finally(() => setLoading(false));
    };

    return (
        <AuthenticationLayout>
            {loading && <Loader />}
            <div className="max-w-[530px] w-full min-h-[530px] bg-white rounded-[20px]">
                <div className="py-5 px-10">
                    <img alt="" src={logo} className="mx-auto" />
                    <Formik
                        initialValues={{
                            fullName: "",
                            email: "",
                            password: "",
                            confirmPassword: "",
                            role: false,
                        }}
                        onSubmit={(values: Values) => {
                            handleSignup(values);
                            console.log(values);
                        }}
                        validationSchema={SigninPageSchema}
                    >
                        {({ errors, touched }) => (
                            <Form className="flex flex-col space-y-10 pt-10 text-lg xl:text-xl max-w-[350px] mx-auto">
                                <FormElement
                                    type="text"
                                    name="fullName"
                                    label="Full Name"
                                    labelFor="fullName"
                                    placeholder="Full Name"
                                    error={
                                        errors.fullName && touched.fullName
                                            ? errors.fullName
                                            : null
                                    }
                                />
                                <FormElement
                                    type="email"
                                    name="email"
                                    label="Email Address"
                                    labelFor="email"
                                    placeholder="Email Address"
                                    error={
                                        errors.email && touched.email
                                            ? errors.email
                                            : null
                                    }
                                />

                                <FormElement
                                    type="password"
                                    name="password"
                                    label="Password"
                                    labelFor="password"
                                    placeholder="Password"
                                    error={
                                        errors.password && touched.password
                                            ? errors.password
                                            : null
                                    }
                                />

                                <FormElement
                                    type="password"
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    labelFor="confirmPassword"
                                    placeholder="Confirm Password"
                                    isAuth
                                    error={
                                        errors.confirmPassword &&
                                        touched.confirmPassword
                                            ? errors.confirmPassword
                                            : null
                                    }
                                />

                                {/* <div className="flex justify-center items-center gap-x-5">
                                    <Field
                                        id="role"
                                        name="role"
                                        type="checkbox"
                                        className="scale-125 checked:bg-primary"
                                    />
                                    <p className="text-base xl:text-lg">
                                        Sign Up as Super Admin
                                    </p>
                                </div> */}

                                <div className="text-center">
                                    <Button
                                        type="submit"
                                        variant="secondary"
                                        size="md"
                                    >
                                        Sign Up
                                    </Button>
                                </div>
                                <p className="text-primary/60 text-center">
                                    Don’t have an account?{" "}
                                    <Link
                                        to="/sign-in"
                                        className="text-primary font-semibold"
                                    >
                                        Login
                                    </Link>
                                </p>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </AuthenticationLayout>
    );
};

const mapStateToProps = ({ authReducer }: any) => ({
    authReducer,
});

export default connect(mapStateToProps, actions)(SigninPage);

// import React, { useState } from "react";
// import * as Yup from "yup";
// import AuthenticationLayout from "../layouts/AuthenticationLayout";
// import logo from "../assets/icons/logo.svg";
// import * as actions from "../store/actions";
// import { Formik, Field, Form } from "formik";
// import Button from "../components/Button";
// import { Link, useNavigate } from "react-router-dom";
// import FormElement from "../components/FormElements";
// import { toast } from "react-toastify";
// import Loader from "../components/Loader";
// import { connect } from "react-redux";

// interface Values {
//     fullName: string;
//     email: string;
//     password: string;
//     confirmPassword: string;
// }

// type SignupPagePropsType = {
//     registerUser: Function;
//     sendVerificationMail: Function;
//     authReducer: any;
// };

// const SignupPageSchema = Yup.object().shape({
//     fullName: Yup.string().min(1, "Too Short!").required("Required"),
//     email: Yup.string().min(1, "Too Short!").required("Required"),
//     password: Yup.string().min(1, "Too Short!").required("Required"),
//     confirmPassword: Yup.string().min(1, "Too Short!").required("Required"),
// });

// const SignupPage = (props: SignupPagePropsType) => {
//     const navigate = useNavigate();
//     const { registerUser, sendVerificationMail } = props;
//     const [loading, setLoading] = useState(false);

//     const handleSignup = async (data: any) => {
//         setLoading(true);
//         let email = data.email;
//         let fullName = data.fullName;
//         await registerUser(data)
//             .then(async ({ status }: any) => {
//                 if (status === 200) {
//                     toast.success("Welcome, admin created ");
//                 }
//             })
//             .then(async () => {
//                 await sendVerificationMail(email).then(
//                     async ({ status }: any) => {
//                         if (status === 200) {
//                             navigate("/verify-email", {
//                                 state: {
//                                     fullName,
//                                     email,
//                                 },
//                             });
//                         }
//                     }
//                 );
//             })
//             .catch(async (error: any) => {})
//             .finally(() => setLoading(false));
//     };
//     return (
//         <AuthenticationLayout>
//             {loading && <Loader />}
//             <div className="max-w-[530px] w-full min-h-[530px] bg-white rounded-[20px]">
//                 <div className="py-5 px-10">
//                     <img alt="" src={logo} className="mx-auto" />
//                     <Formik
//                         initialValues={{
//                             fullName: "",
//                             email: "",
//                             password: "",
//                             confirmPassword: "",
//                         }}
//                         onSubmit={(values: Values) => {
//                             handleSignup(values);
//                         }}
//                         validationSchema={SignupPageSchema}
//                     >
//                         {({ errors, touched }) => (
//                             <Form className="flex flex-col space-y-10 pt-10 text-lg xl:text-xl max-w-[350px] mx-auto">
//                                 <FormElement
//                                     type="text"
//                                     name="fullName"
//                                     label="Full Name"
//                                     labelFor="fullName"
//                                     placeholder="Full Name"
//                                     error={
//                                         errors.fullName && touched.fullName
//                                             ? errors.fullName
//                                             : null
//                                     }
//                                 />
//                                 <FormElement
//                                     type="email"
//                                     name="email"
//                                     label="Email Address"
//                                     labelFor="email"
//                                     placeholder="Email Address"
//                                     error={
//                                         errors.email && touched.email
//                                             ? errors.email
//                                             : null
//                                     }
//                                 />

//                                 <FormElement
//                                     type="password"
//                                     name="password"
//                                     label="Password"
//                                     labelFor="password"
//                                     placeholder="Password"
//                                     error={
//                                         errors.password && touched.password
//                                             ? errors.password
//                                             : null
//                                     }
//                                 />
//                                 <FormElement
//                                     type="password"
//                                     name="confirmPassword"
//                                     label="Confirm Password"
//                                     labelFor="confirmPassword"
//                                     placeholder="Password"
//                                     isAuth
//                                     error={
//                                         errors.confirmPassword &&
//                                         touched.confirmPassword
//                                             ? errors.confirmPassword
//                                             : null
//                                     }
//                                 />

//                                 <div className="flex justify-center items-center gap-x-5">
//                                     <Field
//                                         id="superAdmin"
//                                         name="superAdmin"
//                                         type="checkbox"
//                                         className="scale-125 checked:bg-primary"
//                                     />
//                                     <p className="text-base xl:text-lg">
//                                         I agree with the terms and conditions
//                                     </p>
//                                 </div>

//                                 <div className="text-center">
//                                     <Button
//                                         type="submit"
//                                         variant="secondary"
//                                         size="md"
//                                     >
//                                         Sign up
//                                     </Button>
//                                 </div>
//                                 <p className="text-primary/60 text-center">
//                                     Already have an account?{" "}
//                                     <Link
//                                         to="/sign-in"
//                                         className="text-primary font-semibold"
//                                     >
//                                         Login
//                                     </Link>
//                                 </p>
//                             </Form>
//                         )}
//                     </Formik>
//                 </div>
//             </div>
//         </AuthenticationLayout>
//     );
// };

// const mapStateToProps = ({ authReducer }: any) => ({
//     authReducer,
// });

// export default connect(mapStateToProps, actions)(SignupPage);

// // import React from "react";
// // import AuthenticationLayout from "../layouts/AuthenticationLayout";
// // import logo from "../assets/icons/logo.svg";

// // import { Formik, Field, Form, FormikHelpers } from "formik";
// // import Button from "../components/Button";
// // import { Link } from "react-router-dom";
// // import FormElement from "../components/FormElements";

// // interface Values {
// //     fullName: string;
// //     email: string;
// //     password: string;
// //     confirmPassword: string;
// // }

// // const SignupPage = () => {
// //     return (
// //         <AuthenticationLayout>
// //             <div className="max-w-[530px] w-full min-h-[530px] bg-white rounded-[20px]">
// //                 <div className="py-5 px-10">
// //                     <img alt="" src={logo} className="mx-auto" />

// //                     <Formik
// //                         initialValues={{
// //                             fullName: "",
// //                             email: "",
// //                             password: "",
// //                             confirmPassword: "",
// //                         }}
// //                         onSubmit={(
// //                             values: Values,
// //                             { setSubmitting }: FormikHelpers<Values>
// //                         ) => {
// //                             setTimeout(() => {
// //                                 alert(JSON.stringify(values, null, 2));
// //                                 setSubmitting(false);
// //                             }, 500);
// //                         }}
// //                     >
// //                         <Form className="flex flex-col space-y-10 pt-10 text-lg xl:text-xl max-w-[350px] mx-auto">
// //                             <FormElement
// //                                 type="text"
// //                                 name="fullName"
// //                                 label="Full Name"
// //                                 labelFor="fullName"
// //                                 placeholder="Full Name"
// //                             />
// //                             <FormElement
// //                                 type="email"
// //                                 name="email"
// //                                 label="Email Address"
// //                                 labelFor="email"
// //                                 placeholder="Email Address"
// //                             />

// //                             <FormElement
// //                                 type="password"
// //                                 name="password"
// //                                 label="Password"
// //                                 labelFor="password"
// //                                 placeholder="Password"
// //                             />
// //                             <FormElement
// //                                 type="password"
// //                                 name="confirmPassword"
// //                                 label="Confirm Password"
// //                                 labelFor="confirmPassword"
// //                                 placeholder="Password"
// //                                 isAuth
// //                             />

// //                             <div className="flex justify-center items-center gap-x-5">
// //                                 <Field
// //                                     id="superAdmin"
// //                                     name="superAdmin"
// //                                     type="checkbox"
// //                                     className="scale-125 checked:bg-primary"
// //                                 />
// //                                 <p className="text-base xl:text-lg">
// //                                     I agree with the terms and conditions
// //                                 </p>
// //                             </div>

// //                             <div className="text-center">
// //                                 <Button
// //                                     type="submit"
// //                                     variant="secondary"
// //                                     size="md"
// //                                 >
// //                                     Login
// //                                 </Button>
// //                             </div>
// //                             <p className="text-primary/60 text-center">
// //                                 Already have an account?{" "}
// //                                 <Link
// //                                     to="/sign-in"
// //                                     className="text-primary font-semibold"
// //                                 >
// //                                     Login
// //                                 </Link>
// //                             </p>
// //                         </Form>
// //                     </Formik>
// //                 </div>
// //             </div>
// //         </AuthenticationLayout>
// //     );
// // };

// // export default SignupPage;
