import React from "react";

const CancelSearch = () => {
    return (
        <button
            className="mr-4 rounded-lg border-primary border w-fit px-1 h-8 cursor-pointer text-sm hover:bg-primary hover:text-white-light"
            type="button"
            onClick={() => {
                window.location.reload();
            }}
        >
            Cancel
        </button>
    );
};

export default CancelSearch;
