import { Navigate } from "react-router-dom";
import store from "../store";

export const ProtectedRoute = ({ children }: any) => {
    const {
        authReducer: { user, token },
    } = store.getState();

    console.log(user, "user");
    console.log(token, "token");
    if (!token) {
        return <Navigate to="/sign-in" />;
    }
    return children;
};
