import React, { useEffect } from "react";
import { Provider } from "react-redux";
import Alert from "./components/AlertComponent";
import { Outlet } from "react-router-dom";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
    return (
        <>
            <div className="min-h-screen w-screen" id="top">
                {/* <ToastContainer /> */}
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Outlet />
                    </PersistGate>
                </Provider>
            </div>
            <div className="fixed top-0 right-0">
                <ToastContainer />
            </div>
        </>
    );
}

export default App;
