import React, { useState } from "react";
import moment from "moment";
import DashboardLayout from "../layouts/DashboardLayout";
import NavAccount from "../components/NavAccount";
import chevronDown from "../assets/icons/Chevron_Down.svg";
import closeIcon from "../assets/icons/close.svg";
import { exportExcel } from "../utils/exportData";
import { useLocation, useNavigate } from "react-router-dom";

const DebtorInfo = () => {
    const location: any = useLocation();
    const state = location?.state;

    const debtor = state?.debtor;
    const user = state?.user;
    const type = state?.type;

    const navigate = useNavigate();

    let debtorData = [
        {
            title: "firstName",
            value: debtor?.firstName,
        },
        {
            title: "middleName",
            value: debtor?.middleName,
        },
        {
            title: "lastName",
            value: debtor?.lastName,
        },
        {
            title: "email",
            value: debtor?.email,
        },
        {
            title: "companyName",
            value: debtor?.companyName,
        },
        {
            title: "totalIndebtedness",
            value: debtor?.totalIndebtedness,
        },
        {
            title: "houseAddress",
            value: debtor?.houseAddress,
        },
        {
            title: "customerType",
            value: debtor?.customerType,
        },
        {
            title: "accountName",
            value: debtor?.accountName,
        },
        {
            title: "bankName",
            value: debtor?.bankName,
        },
        {
            title: "facilityType",
            value: debtor?.facilityType,
        },
        {
            title: "category",
            value: debtor?.category,
        },
        {
            title: "riskLevel",
            value: debtor?.riskLevel,
        },
        {
            title: "loanAmount",
            value: debtor?.loanAmount,
        },
        {
            title: "disbursementDate",
            value: moment(debtor?.disbursementDate).format("MMM Do YYYY"),
        },
        {
            title: "loanRepaymentStartDate",
            value: moment(debtor?.loanRepaymentStartDate).format("MMM Do YYYY"),
        },
        {
            title: "loanRepaymentMaturityDate",
            value: moment(debtor?.loanRepaymentMaturityDate).format(
                "MMM Do YYYY"
            ),
        },
        {
            title: "informationSources",
            value: debtor?.informationSources,
        },
        {
            title: "publicationDate",
            value: moment(debtor?.publicationDate).format("MMM Do YYYY"),
        },
        {
            title: "detailsOfOffenceCommitted",
            value: debtor?.detailsOfOffenceCommitted,
        },
        {
            title: "detailsOfOffenceCommitted",
            value: debtor?.detailsOfOffenceCommitted,
        },
        {
            title: "nameOfDirectors",
            value: debtor?.nameOfDirectors,
        },
    ];

    let userData = [
        {
            title: "fullName",
            value: user?.fullName,
        },
        {
            title: "Email",
            value: user?.email,
        },
        {
            title: "Deactivated",
            value: user?.isDeactivated,
        },
        {
            title: "Role",
            value: user?.role,
        },
    ];

    console.log(state, "state");

    const [toggleDownload, setToggleDownload] = useState(false);
    return (
        <DashboardLayout>
            <div className="grow">
                <div className="p-5">
                    <div className="flex justify-end pt-5 mb-6">
                        <NavAccount />
                    </div>

                    <div className="pl-5">
                        <span
                            onClick={() => navigate(-1)}
                            className="hover:cursor-pointer"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                                />
                            </svg>
                        </span>
                    </div>

                    <div>
                        <div className="flex items-center justify-between hover:cursor-pointer p-5 pt-14 mb-10">
                            <h1 className="font-semibold text-2xl xl:text-[32px]">
                                View {type === "user" ? "User" : "Debtor"}
                            </h1>

                            <div>
                                <div
                                    className="flex items-center gap-x-3 relative"
                                    onClick={() => setToggleDownload(true)}
                                >
                                    <p className="font-semibold text-base xl:text-lg">
                                        Download as
                                    </p>
                                    <img alt="" src={chevronDown} />
                                </div>

                                {toggleDownload && (
                                    <div className="absolute bottom-0 right-0 w-[150px] -mb-5 h-[100px] bg-white-light rounded-lg z-[999] py-10 flex flex-col gap-8 shadow-md border border-primary">
                                        <img
                                            alt=""
                                            src={closeIcon}
                                            className="w-5 h-5 hover:cursor-pointer ml-auto absolute top-3 right-3"
                                            onClick={() =>
                                                setToggleDownload(false)
                                            }
                                        />

                                        <div
                                            className="px-7 hover:underline hover:cursor-pointer"
                                            onClick={() =>
                                                exportExcel(
                                                    type === "user"
                                                        ? userData
                                                        : debtorData,
                                                    type === "user"
                                                        ? "User"
                                                        : "Debtor"
                                                )
                                            }
                                        >
                                            Excel
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col gap-y-9 text-lg 2xl:text-2xl px-5">
                            {debtor && (
                                <>
                                    {debtorData?.map((item, index) => {
                                        return (
                                            <div
                                                className="grid grid-cols-3"
                                                key={index}
                                            >
                                                <p className="font-semibold">
                                                    {item?.title}
                                                </p>
                                                <p>{String(item?.value)}</p>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                            {user && (
                                <>
                                    {userData?.map((item, index) => {
                                        return (
                                            <div
                                                className="grid grid-cols-3"
                                                key={index}
                                            >
                                                <p className="font-semibold">
                                                    {item?.title}
                                                </p>
                                                <p>{String(item?.value)}</p>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default DebtorInfo;
