import React, { useState } from "react";
import * as Yup from "yup";
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import logo from "../assets/icons/logo.svg";

import Button from "../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import Loader from "../components/Loader";

const VerifyEmailScreen = (props: any) => {
    const location: any = useLocation();
    const navigate = useNavigate();
    const state = location?.state;

    return (
        <AuthenticationLayout>
            <div className="max-w-[450px] w-full min-h-[400px] bg-white rounded-[20px]">
                <div className="py-5 px-10">
                    <img alt="" src={logo} className="mx-auto" />

                    <div className="text-center">
                        <h3 className="text-1xl xl:text-[22px] font-semibold mt-5">
                            You have successfully created an account
                        </h3>
                        <p className="text-1xl xl:text-[16px] font-normal mt-5 mb-10">
                            Hi {state?.fullName}, an email verification link has
                            been sent to {state?.email}
                        </p>
                        <Button
                            onClick={() => navigate("/sign-in")}
                            type="submit"
                            variant="secondary"
                            size="md"
                        >
                            Login
                        </Button>
                    </div>
                </div>
            </div>
        </AuthenticationLayout>
    );
};

const mapStateToProps = ({ authReducer }: any) => ({
    authReducer,
});

export default connect(mapStateToProps, actions)(VerifyEmailScreen);
