import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import DashboardLayout from "../layouts/DashboardLayout";
import NavAccount from "../components/NavAccount";
import chevronDown from "../assets/icons/Chevron_Down.svg";
import FormElement from "../components/FormElements";
import { Field, Form, Formik, FormikHelpers } from "formik";
import Button from "../components/Button";
import { Link, useNavigate } from "react-router-dom";
import * as xlsx from "xlsx";
import closeIcon from "../assets/icons/close.svg";
import * as actions from "../store/actions";
import Loader from "../components/Loader";
import { storeBulkInsert } from "../utils/storeHelper";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { handleAxiosError } from "../utils/errors";
import { SERVER } from "../config/axios";
interface Values {
    firstName: string;
    lastName: string;
    middleName: string;
    email: string;
    companyName: string;
    totalIndebtedness: string;
    nameOfDirectors: string;
    houseAddress: string;
    customerType: string;
    accountName: string;
    accountNumber: string;
    bankName: string;
    facilityType: string;
    category: string;
    riskLevel: string;
    loanAmount: string;
    disbursementDate: string;
    loanRepaymentStartDate: string;
    loanRepaymentMaturityDate: string;
    informationSources: string;
    publicationDate: string;
    detailsOfOffenceCommitted: string;
}

type DebtorProfilingPagePropsType = {
    addDebtor: Function;
    authReducer: any;
};

interface UploadValue {
    file: any;
}

const DebtorProfilingPageSchema = Yup.object().shape({
    firstName: Yup.string().min(1, "Too Short!").required("Required"),
    lastName: Yup.string().min(1, "Too Short!").required("Required"),
    // middleName: Yup.string().min(1, "Too Short!").required("Required"),
    email: Yup.string().email(),
    // companyName: Yup.string().min(1, "Too Short!").required("Required"),
    // totalIndebtedness: Yup.string().min(1, "Too Short!").required("Required"),
    // nameOfDirectors: Yup.string().min(1, "Too Short!").required("Required"),
    // houseAddress: Yup.string().min(1, "Too Short!").required("Required"),
    // customerType: Yup.string().min(1, "Too Short!").required("Required"),
    // accountName: Yup.string().min(1, "Too Short!").required("Required"),
    // accountNumber: Yup.string()
    //     .min(10, "Too Short!")
    //     .max(10, "Too Short!")
    //     .required("Required"),
    // bankName: Yup.string().min(1, "Too Short!").required("Required"),
    // facilityType: Yup.string().min(1, "Too Short!").required("Required"),
    // category: Yup.string().min(1, "Too Short!").required("Required"),
    // riskLevel: Yup.string().min(1, "Too Short!").required("Required"),
    // loanAmount: Yup.string().min(1, "Too Short!").required("Required"),
    // disbursementDate: Yup.string().min(1, "Too Short!").required("Required"),
    // loanRepaymentStartDate: Yup.string()
    //     .min(1, "Too Short!")
    //     .required("Required"),
    // loanRepaymentMaturityDate: Yup.string()
    //     .min(1, "Too Short!")
    //     .required("Required"),
    // informationSources: Yup.string().min(1, "Too Short!").required("Required"),
    // publicationDate: Yup.string().min(1, "Too Short!").required("Required"),
});

const DebtorProfilingPage = (props: DebtorProfilingPagePropsType) => {
    const {
        addDebtor,
        authReducer: { debtors, user },
    } = props;
    const [data, setData]: any = useState([]);
    const [toggleUpload, setToggleUpload] = useState(false);
    const [modal, setModal] = useState(false);
    const [uploadStatus, setUploadStatus]: any = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function upload(e: any) {
        e.preventDefault();
        setUploadStatus("pending");
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const data: any = e.target.result;
            const workbook: any = xlsx.read(data, { type: "array" });
            const sheetName: any = workbook.SheetNames[0];
            const worksheet: any = workbook.Sheets[sheetName];
            const json: any = xlsx.utils.sheet_to_json(worksheet);
            setTimeout(() => {
                setData(json);
                console.log(json);
                setUploadStatus("success");
            }, 3000);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
    }

    const handleAddDebtor = useCallback(
        async (data: any) => {
            setLoading(true);
            await addDebtor({
                ...data,
                user: user?._id,
                customerType: data.customerType.toUpperCase(),
                riskLevel: data.riskLevel.toUpperCase(),
            })
                .then(async ({ status, data }: any) => {
                    console.log(data?.data, "data?.data debtors");
                    if (status === 201) {
                        storeBulkInsert({
                            debtors: [...debtors, data?.data?.debtor],
                        });
                        toast.success("Debtor added successfully");
                        navigate("/dashboard/debtors");
                    } else {
                        console.log("hello");
                    }
                })
                .catch(async (error: any) => {
                    if (error.response.data.error.code === 11000) {
                        toast.error("Email address has already been taken");
                    } else {
                        console.log(error.response);
                        toast.error("Something went wrong, please try again!");
                    }
                })
                .finally(() => setLoading(false));
        },
        [addDebtor, debtors, navigate, user?._id]
    );

    const bulkUpload = async () => {
        console.log(data, "data data");
        let body = {
            userId: user?._id,
            debtors: [...data],
        };
        console.log(body)
        setLoading(true);
        SERVER.post("/debtors", body)
            .then(({ status, data }) => {
                if (status === 201) {
                    toast.success(
                        `Uploaded ${data?.data?.debtors?.length} debtors successfully`
                    );
                    setModal(false)
                }
            })
            .catch((err) => {
                toast.error("Error uploading debtors!");
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <DashboardLayout>
                <div className="grow">
                    <div className="p-5">
                        <div className="w-full pt-8 flex justify-end">
                            <NavAccount />
                        </div>

                        <div className="flex justify-between items-center">
                            <h3 className="text-2xl xl:text-[32px] font-semibold mb-10">
                                Debtor Profiling
                            </h3>

                            <div>
                                <div
                                    className="text-base xl:text-lg font-semibold flex items-center gap-x-3 hover:cursor-pointer"
                                    onClick={() => setToggleUpload(true)}
                                >
                                    <span>Upload debtors list</span>
                                    <img alt="" src={chevronDown} />
                                </div>
                                {toggleUpload && (
                                    <div className="absolute top-0 right-0 w-[150px] -mt-16 h-[150px] bg-white-light rounded-lg z-[10] pt-8 flex flex-col gap-8 shadow-md border border-primary">
                                        <img
                                            alt=""
                                            src={closeIcon}
                                            className="w-5 h-5 hover:cursor-pointer ml-auto absolute top-3 right-3"
                                            onClick={() =>
                                                setToggleUpload(false)
                                            }
                                        />

                                        <div
                                            className="px-7 hover:underline hover:cursor-pointer"
                                            onClick={() => setModal(true)}
                                        >
                                            Excel
                                        </div>
                                        {/* <div className="px-7 hover:underline hover:cursor-pointer">
                                            CSV
                                        </div> */}
                                    </div>
                                )}
                            </div>
                        </div>

                        <Formik
                            initialValues={{
                                firstName: "",
                                lastName: "",
                                middleName: "",
                                email: "",
                                companyName: "",
                                totalIndebtedness: "",
                                nameOfDirectors: "",
                                houseAddress: "",
                                customerType: "",
                                accountName: "",
                                accountNumber: "",
                                bankName: "",
                                facilityType: "",
                                category: "",
                                riskLevel: "",
                                loanAmount: "",
                                disbursementDate: "",
                                loanRepaymentStartDate: "",
                                loanRepaymentMaturityDate: "",
                                informationSources: "",
                                publicationDate: "",
                                detailsOfOffenceCommitted: "",
                            }}
                            onSubmit={(
                                values: Values,
                                { setSubmitting }: FormikHelpers<Values>
                            ) => {
                                handleAddDebtor(values);
                            }}
                            validationSchema={DebtorProfilingPageSchema}
                        >
                            {({ errors, touched, setFieldValue }) => {
                                console.log(errors, "eer");
                                return (
                                    <Form>
                                        <div className="flex flex-col gap-y-16">
                                            <div>
                                                <h4 className="text-xl xl:text-2xl font-semibold mb-4">
                                                    Personal Information
                                                </h4>
                                                <div className="grid grid-cols-3 gap-x-8 gap-y-6">
                                                    <FormElement
                                                        id="firstName"
                                                        type="text"
                                                        name="firstName"
                                                        label="First Name"
                                                        labelFor="firstName"
                                                        placeholder="John"
                                                        error={
                                                            errors.firstName &&
                                                            touched.firstName
                                                                ? errors.firstName
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="middleName"
                                                        type="text"
                                                        name="middleName"
                                                        label="Middle Name"
                                                        labelFor="middleName"
                                                        placeholder="Luke"
                                                        error={
                                                            errors.middleName &&
                                                            touched.middleName
                                                                ? errors.middleName
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="lastName"
                                                        type="text"
                                                        name="lastName"
                                                        label="Last Name"
                                                        labelFor="lastName"
                                                        placeholder="Doe"
                                                        error={
                                                            errors.lastName &&
                                                            touched.lastName
                                                                ? errors.lastName
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="companyName"
                                                        type="text"
                                                        name="companyName"
                                                        label="Company Name"
                                                        labelFor="companyName"
                                                        placeholder="DLM Capital Group"
                                                        error={
                                                            errors.companyName &&
                                                            touched.companyName
                                                                ? errors.companyName
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="totalIndebtedness"
                                                        type="number"
                                                        name="totalIndebtedness"
                                                        label="Total Indebtedness"
                                                        labelFor="totalIndebtedness"
                                                        placeholder="N200,000"
                                                        error={
                                                            errors.totalIndebtedness &&
                                                            touched.totalIndebtedness
                                                                ? errors.totalIndebtedness
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="email"
                                                        type="text"
                                                        name="email"
                                                        label="Email"
                                                        labelFor="email"
                                                        placeholder="Email"
                                                        error={
                                                            errors.email &&
                                                            touched.email
                                                                ? errors.email
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="nameOfDirectors"
                                                        type="text"
                                                        name="nameOfDirectors"
                                                        label="Name Of Directors"
                                                        labelFor="nameOfDirectors"
                                                        placeholder="Name"
                                                        error={
                                                            errors.nameOfDirectors &&
                                                            touched.nameOfDirectors
                                                                ? errors.nameOfDirectors
                                                                : null
                                                        }
                                                    />

                                                    <FormElement
                                                        id="houseAddress"
                                                        type="text"
                                                        name="houseAddress"
                                                        label="House Address"
                                                        labelFor="houseAddress"
                                                        placeholder="22 house address street, lagos state"
                                                        error={
                                                            errors.houseAddress &&
                                                            touched.houseAddress
                                                                ? errors.houseAddress
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="customerType"
                                                        type="select"
                                                        name="customerType"
                                                        label="Customer Type"
                                                        labelFor="customerType"
                                                        options={[
                                                            "Individual",
                                                            "Corporate",
                                                        ]}
                                                        error={
                                                            errors.customerType &&
                                                            touched.customerType
                                                                ? errors.customerType
                                                                : null
                                                        }
                                                        onClick={(field: any) =>
                                                            setFieldValue(
                                                                "customerType",
                                                                field.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <h4 className="text-2xl font-semibold mb-4">
                                                    Account Information
                                                </h4>
                                                <div className="grid grid-cols-3 gap-x-8 gap-y-6">
                                                    <FormElement
                                                        id="accountName"
                                                        type="text"
                                                        name="accountName"
                                                        label="Account Name"
                                                        labelFor="accountName"
                                                        placeholder="DLM Capital Group"
                                                        error={
                                                            errors.accountName &&
                                                            touched.accountName
                                                                ? errors.accountName
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="accountNumber"
                                                        type="text"
                                                        name="accountNumber"
                                                        label="Account Number"
                                                        labelFor="accountNumber"
                                                        placeholder="1234567890"
                                                        maxlength="10"
                                                        error={
                                                            errors.accountNumber &&
                                                            touched.accountNumber
                                                                ? errors.accountNumber
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="bankName"
                                                        type="text"
                                                        name="bankName"
                                                        label="Bank Name"
                                                        labelFor="Bank Name"
                                                        placeholder="Links Microfinance Bank"
                                                        error={
                                                            errors.bankName &&
                                                            touched.bankName
                                                                ? errors.bankName
                                                                : null
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <h4 className="text-xl xl:text-2xl font-semibold mb-4">
                                                    Credit Loan Information
                                                </h4>
                                                <div className="grid grid-cols-3 gap-x-8 gap-y-6 mb-6">
                                                    <FormElement
                                                        id="facilityType"
                                                        type="select"
                                                        onChange={() =>
                                                            alert("hello")
                                                        }
                                                        onClick={(field: any) =>
                                                            setFieldValue(
                                                                "facilityType",
                                                                field.target
                                                                    .value
                                                            )
                                                        }
                                                        name="facilityType"
                                                        label="Facility Type"
                                                        labelFor="facilityType"
                                                        options={[
                                                            "Term Loan",
                                                            "Overdraft",
                                                            "Sydicated Facility",
                                                            "Contract Finance",
                                                            "Invoice Discount",
                                                            "Mortgage Loans",
                                                            "Personal Loan",
                                                            "Others",
                                                        ]}
                                                        error={
                                                            errors.facilityType &&
                                                            touched.facilityType
                                                                ? errors.facilityType
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="category"
                                                        type="select"
                                                        onClick={(field: any) =>
                                                            setFieldValue(
                                                                "category",
                                                                field.target
                                                                    .value
                                                            )
                                                        }
                                                        name="category"
                                                        label="Category"
                                                        labelFor="category"
                                                        options={[
                                                            "Financial Fraud",
                                                            "Financial Indebtedness",
                                                            "Drug Related Issues",
                                                            "Others",
                                                        ]}
                                                        error={
                                                            errors.category &&
                                                            touched.category
                                                                ? errors.category
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="riskLevel"
                                                        type="select"
                                                        onClick={(field: any) =>
                                                            setFieldValue(
                                                                "riskLevel",
                                                                field.target
                                                                    .value
                                                            )
                                                        }
                                                        name="riskLevel"
                                                        label="Risk Level"
                                                        labelFor="riskLevel"
                                                        options={[
                                                            "Low",
                                                            "Medium",
                                                            "High",
                                                            "Extremely High",
                                                        ]}
                                                        error={
                                                            errors.riskLevel &&
                                                            touched.riskLevel
                                                                ? errors.riskLevel
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="loanAmount"
                                                        type="number"
                                                        name="loanAmount"
                                                        label="Loan Amount"
                                                        labelFor="loanAmount"
                                                        placeholder="100,000"
                                                        error={
                                                            errors.loanAmount &&
                                                            touched.loanAmount
                                                                ? errors.loanAmount
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="disbursementDate"
                                                        type="date"
                                                        name="disbursementDate"
                                                        label="Disbursement Date"
                                                        labelFor="disbursementDate"
                                                        error={
                                                            errors.disbursementDate &&
                                                            touched.disbursementDate
                                                                ? errors.disbursementDate
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="loanRepaymentStartDate"
                                                        type="date"
                                                        name="loanRepaymentStartDate"
                                                        label="Loan Repayment Start Date"
                                                        labelFor="loanRepaymentStartDate"
                                                        error={
                                                            errors.loanRepaymentStartDate &&
                                                            touched.loanRepaymentStartDate
                                                                ? errors.loanRepaymentStartDate
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="loanRepaymentMaturityDate"
                                                        type="date"
                                                        name="loanRepaymentMaturityDate"
                                                        label="Loan Repayment Maturity Date"
                                                        labelFor="loanRepaymentMaturityDate"
                                                        error={
                                                            errors.loanRepaymentMaturityDate &&
                                                            touched.loanRepaymentMaturityDate
                                                                ? errors.loanRepaymentMaturityDate
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        id="informationSources"
                                                        type="text"
                                                        name="informationSources"
                                                        label="Information Sources"
                                                        labelFor="informationSources"
                                                        error={
                                                            errors.informationSources &&
                                                            touched.informationSources
                                                                ? errors.informationSources
                                                                : null
                                                        }
                                                    />

                                                    <FormElement
                                                        id="publicationDate"
                                                        type="date"
                                                        name="publicationDate"
                                                        label="Publication Date"
                                                        labelFor="publicationDate"
                                                        error={
                                                            errors.publicationDate &&
                                                            touched.publicationDate
                                                                ? errors.publicationDate
                                                                : null
                                                        }
                                                    />
                                                </div>
                                                <div className="flex flex-col gap-y-14">
                                                    <FormElement
                                                        id="detailsofOffenceCommitted"
                                                        type="textarea"
                                                        name="detailsofOffenceCommitted"
                                                        label="Details of Offence Committed (Previous and current)"
                                                        labelFor="detailsofOffenceCommitted"
                                                        placeholder="State reason..."
                                                        onClick={(field: any) =>
                                                            setFieldValue(
                                                                "detailsofOffenceCommitted",
                                                                field.target
                                                                    .value
                                                            )
                                                        }
                                                    />

                                                    <div className="w-[780px] mx-auto">
                                                        <Button
                                                            type="submit"
                                                            size="full"
                                                        >
                                                            Add Debtor
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </DashboardLayout>
            {modal && (
                <div className="fixed w-screen h-screen top-0 left-0 bottom-0 bg-primary/[18%] z-50 flex items-center justify-center">
                    <div className="w-[770px] h-[424px] bg-white-light shadow-sm rounded-[20px] relative">
                        <img
                            alt=""
                            src={closeIcon}
                            className="absolute top-8 right-7 hover:cursor-pointer z-50"
                            onClick={() => {
                                setModal(false);
                                setToggleUpload(false);
                                setUploadStatus(null);
                            }}
                        />

                        <div className="pt-12">
                            <h2 className="font-semibold text-center text-xl xl:text-[32px] mb-10">
                                Upload Excel
                            </h2>
                            <div className="flex flex-col gap-y-5 w-[334px] mx-auto">
                                <FormElement
                                    pure
                                    type="file"
                                    name="file"
                                    label="Upload File"
                                    labelFor="file"
                                    accept={".xlsx"}
                                    onChange={upload}
                                />

                                {uploadStatus === "pending" && (
                                    <p className="text-center text-lg font-semibold">
                                        Converting...
                                    </p>
                                )}
                                {uploadStatus === "success" && (
                                    <p className="text-success text-center text-lg font-semibold">
                                        Done!
                                    </p>
                                )}

                                <Button
                                    type="submit"
                                    variant="secondary"
                                    size="full"
                                    onClick={() =>
                                        data.length < 1 ? null : bulkUpload()
                                    }
                                    disabled={data.length < 1 ? true : false}
                                >
                                    Upload
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = ({ authReducer }: any) => ({
    authReducer,
});

export default connect(mapStateToProps, actions)(DebtorProfilingPage);
