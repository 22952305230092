import React from "react";
import bg1 from "../assets/images/bg1.svg";
import bg2 from "../assets/images/bg2.svg";

type _props = {
    children: React.ReactNode;
};

const AuthenticationLayout = (props: _props) => {
    const { children } = props;
    return (
        <div className="flex items-center justify-center w-full min-h-screen bg-gray-dark py-20">
            <img alt="" src={bg1} className="fixed top-0 left-0" />
            <img alt="" src={bg2} className="fixed bottom-0 right-0" />
            {children}
        </div>
    );
};

export default AuthenticationLayout;
