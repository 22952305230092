import React, { useCallback, useEffect, useState } from "react";
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import logo from "../assets/icons/logo.svg";
import * as Yup from "yup";
import { Formik, Form, FormikHelpers } from "formik";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import FormElement from "../components/FormElements";
import { forgotPassword } from "../store/actions";
import { SERVER } from "../config/axios";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { handleAxiosError } from "../utils/errors";

interface Values {
    email: string;
}

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().min(1, "Too Short!").required("Required").email(),
});

const ForgotPasswordPage = () => {
    const [success, setSuccess]: any = useState("");
    const [loading, setLoading] = useState(false);

    const handleForgotPassword = async (email: string) => {
        setLoading(true);
        try {
            const { status } = await SERVER.post("/users/forgot-password", {
                email,
            });

            if (status === 200) {
                setSuccess("Check your mail to reset password");
                toast.success("Check your mail to reset password");
            }
        } catch (error) {
            handleAxiosError(error);
        }
        setLoading(false);
    };

    return (
        <AuthenticationLayout>
            {loading && <Loader />}
            <div className="max-w-[530px] w-full min-h-[530px] bg-white rounded-[20px]">
                <div className="py-5 px-10">
                    <img alt="" src={logo} className="mx-auto" />

                    <Formik
                        initialValues={{
                            email: "",
                        }}
                        onSubmit={(values: Values) => {
                            handleForgotPassword(values.email);
                        }}
                        validationSchema={forgotPasswordSchema}
                    >
                        {({ errors, touched }) => {
                            return (
                                <Form className="flex flex-col space-y-10 pt-10 text-lg xl:text-xl max-w-[350px] mx-auto text-primary">
                                    <div className="flex flex-col text-center gap-y-8">
                                        <h1 className="text-xl xl:text-2xl font-semibold">
                                            Forgot Password
                                        </h1>
                                        <p className="text-base xl:text-lg text-primary/60">
                                            Enter your email address
                                        </p>
                                        <p
                                            className={`text-1xl xl:text-[16px] font-normal mt-5`}
                                        >
                                            {success}
                                        </p>
                                    </div>
                                    <FormElement
                                        type="email"
                                        name="email"
                                        label="Email Address"
                                        labelFor="email"
                                        placeholder="Email Address"
                                        error={
                                            errors.email && touched.email
                                                ? errors.email
                                                : null
                                        }
                                    />
                                    <div className="text-center">
                                        <Button
                                            type="submit"
                                            variant="secondary"
                                            size="md"
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                    <p className="text-primary/60 text-center">
                                        Go back to{" "}
                                        <Link
                                            to="/sign-in"
                                            className="text-primary font-semibold"
                                        >
                                            Sign In
                                        </Link>
                                    </p>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </AuthenticationLayout>
    );
};

export default ForgotPasswordPage;
