export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const SET_DEBTORS = "SET_DEBTORS";
export const SET_ACTIVITIES = "SET_ACTIVITIES";
export const USER_TYPE = "USER_TYPE";
export const RESET_AUTH = "RESET_AUTH";
export const USER_LOGIN = "USER_LOGIN";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_AUTHENTICATION = "REMOVE_AUTHENTICATION";
export const SET_PASSWORD_HASH = "SET_PASSWORD_HASH";
export const SET_ACCOUNT_SETUP = "SET_ACCOUNT_SETUP";
export const SET_CONFIG = "SET_CONFIG";
export const RESET_STATE = "RESET_STATE";
export const SET_LASTPAGE = "SET_LASTPAGE";
