import * as TYPES from "../../dtos";

const initialState: any = {
    user: null,
    users: [],
    debtors: [],
    activities: [],
    token: null,
    isLoggedIn: false,
    lastPage: false,
};

export const authReducer = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case TYPES.RESET_AUTH:
            return initialState;
        case TYPES.USER_LOGIN:
            return {
                ...state,
                token: payload,
                isLoggedIn: true,
            };

        case TYPES.UPDATE_USER:
            return {
                ...state,
                user: { ...state.user, ...payload },
            };

        case TYPES.SET_USER:
            return {
                ...state,
                user: payload,
            };
        case TYPES.SET_DEBTORS:
            return {
                ...state,
                debtors: payload,
            };
        case TYPES.SET_ACTIVITIES:
            return {
                ...state,
                activities: payload,
            };
        case TYPES.SET_USERS:
            return {
                ...state,
                users: payload,
            };
        case TYPES.SET_LASTPAGE:
            return {
                ...state,
                lastPage: payload,
            };

        case TYPES.LOGOUT:
            return {
                ...state,
                token: null,
                isLoggedIn: false,
                user: null,
            };

        case TYPES.REMOVE_AUTHENTICATION:
            return {
                ...state,
                token: null,
                user: null,
                users: [],
                debtors: [],
                activities: [],
                isLoggedIn: false,
                lastPage: false,
            };

        default:
            return state;
    }
};
