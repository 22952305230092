import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import DashboardLayout from "../layouts/DashboardLayout";
import Table from "../components/TableComponent";
import { Form, Formik, FormikHelpers } from "formik";
import Button from "../components/Button";
import FormElement from "../components/FormElements";
import NavAccount from "../components/NavAccount";
import chevronDown from "../assets/icons/Chevron_Down.svg";
import { exportExcel } from "../utils/exportData";
import closeIcon from "../assets/icons/close.svg";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import { storeBulkInsert } from "../utils/storeHelper";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import {
    useNavigate,
    createSearchParams,
    useSearchParams,
} from "react-router-dom";
import { handleAxiosError } from "../utils/errors";
import { SERVER } from "../config/axios";
import CancelSearch from "../components/CancelSearch";

interface Values {
    email: string;
    fullName: string;
}

type ManageUsersPagePropsType = {
    authReducer: any;
    makeSuperAdmin: Function;
    deactivateUser: Function;
    fetchUsers: Function;
};

const ManageUsersPageSchema = Yup.object().shape({
    search: Yup.string().min(1, "Too Short!").required("Required"),
});

const ManageUsersPage = (props: ManageUsersPagePropsType) => {
    const { authReducer, deactivateUser, makeSuperAdmin, fetchUsers } = props;
    const { users, user } = authReducer;
    const [loading, setLoading] = useState(false);
    const [usersData, setUsersData]: any = useState([]);
    const [toggleDownload, setToggleDownload] = useState(false);
    const filteredData = usersData;
    const [isLastPage, setIsLastPage] = useState(false);

    const [pageNumber, setPageNumber] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [pages, setPages]: any = useState(null);

    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");

    console.log(users, "users");

    const prevPage = async () => {
        if (currentPage > 1) {
            setLoading(true);
            setTimeout(() => {
                setCurrentPage((currentPage) => currentPage - 1);
                setLoading(false);
            }, 1000);
        }
    };

    const nextPage = async () => {
        if (currentPage < totalPageCount) {
            setLoading(true);
            setTimeout(() => {
                setCurrentPage((currentPage) => currentPage + 1);
                setLoading(false);
            }, 1000);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDeactivateUser = async (id: string) => {
        setLoading(true);
        await SERVER.patch(`/users/${id}`)
            .then(async ({ status, data }: any) => {
                if (status === 200) {
                    toast.success("User status updated");
                }
            })
            .then(async () => {
                fetchData();
            })
            .catch(async (error: any) => {
                handleAxiosError(error);
            })
            .finally(() => setLoading(false));
    };

    const filteredSearch = usersData?.filter((el: any) => {
        console.log(el);
        return (
            el?.fullName.toLowerCase().includes(fullName.toLowerCase()) &&
            el?.email?.toLowerCase().includes(email?.toLowerCase())
        );
    });

    const totalPageCount = Math.ceil(filteredSearch?.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSearch.slice(
        indexOfFirstItem,
        indexOfLastItem
    );

    const fetchData = async () => {
        await SERVER.get("/users")
            .then(async ({ status, data }: any) => {
                if (status === 200) {
                    setUsersData(data?.data);
                }
            })
            .catch((error) => handleAxiosError(error))
            .finally(() => setLoading(false));
    };

    const handleMakeSuperAdmin = async (id: string, role: string) => {
        setLoading(true);
        await SERVER.patch(`/users/admin/${id}`, {
            role,
        })
            .then(async ({ status, data }: any) => {
                if (status === 200) {
                    toast.success("User role updated");
                }
            })
            .then(async () => {
                fetchData();
            })
            .catch(async (error: any) => {
                handleAxiosError(error);
            })
            .finally(() => setLoading(false));
    };

    const handleSearch = (values: any) => {
        values.email && setEmail(values.email);

        values.fullName && setFullName(values.fullName);
    };

    return (
        <>
            <DashboardLayout>
                <div className="grow">
                    <div className="pt-10">
                        <div className="p-5">
                            <Formik
                                initialValues={{
                                    fullName: "",
                                    email: "",
                                }}
                                onSubmit={(
                                    values: Values,
                                    { setSubmitting }: FormikHelpers<Values>
                                ) => {
                                    handleSearch(values);
                                }}
                            >
                                {({ errors, touched, setFieldValue }) => {
                                    return (
                                        <Form>
                                            <div className="container flex items-end justify-between w-full gap-x-5">
                                                <div className="grid grid-cols-5 gap-x-3">
                                                    <FormElement
                                                        type="text"
                                                        name="fullName"
                                                        id="fullName"
                                                        label="Full Name"
                                                        placeholder="Full Name"
                                                        error={
                                                            errors.fullName &&
                                                            touched.fullName
                                                                ? errors.fullName
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        type="text"
                                                        name="email"
                                                        id="email"
                                                        label="Email"
                                                        placeholder="Email"
                                                        error={
                                                            errors.email &&
                                                            touched.email
                                                                ? errors.email
                                                                : null
                                                        }
                                                    />
                                                </div>
                                                <div className="flex items-center">
                                                    <CancelSearch />
                                                    <Button
                                                        size="md"
                                                        variant="secondary"
                                                    >
                                                        Search
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>

                        <div className="flex items-center justify-between hover:cursor-pointer p-5 pt-14">
                            <h1 className="font-semibold text-2xl xl:text-[32px]">
                                Manage Users
                            </h1>

                            <div>
                                <div
                                    className="flex items-center gap-x-3 relative"
                                    onClick={() => setToggleDownload(true)}
                                >
                                    <p className="font-semibold text-base xl:text-lg">
                                        Download as
                                    </p>
                                    <img alt="" src={chevronDown} />
                                </div>

                                {toggleDownload && (
                                    <div className="absolute bottom-0 right-0 w-[150px] -mb-5 h-[100px] bg-white-light rounded-lg z-[999] py-10 flex flex-col gap-8 shadow-md border border-primary">
                                        <img
                                            alt=""
                                            src={closeIcon}
                                            className="w-5 h-5 hover:cursor-pointer ml-auto absolute top-3 right-3"
                                            onClick={() =>
                                                setToggleDownload(false)
                                            }
                                        />

                                        <div
                                            className="px-7 hover:underline hover:cursor-pointer"
                                            onClick={() =>
                                                exportExcel(
                                                    filteredSearch,
                                                    "Debtors"
                                                )
                                            }
                                        >
                                            Excel
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-5">
                            <Table
                                type="USERS"
                                data={currentItems}
                                user={user}
                                isPaginated
                                onClickDeactivateUser={(id: string) =>
                                    handleDeactivateUser(id)
                                }
                                onClickSuperAdmin={(id: string, role: string) =>
                                    handleMakeSuperAdmin(id, role)
                                }
                                pageNumber={currentPage}
                                prevPage={prevPage}
                                nextPage={nextPage}
                                pages={totalPageCount}
                            />
                        </div>
                    </div>
                </div>
            </DashboardLayout>
            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = ({ authReducer }: any) => ({
    authReducer,
});

export default connect(mapStateToProps, actions)(ManageUsersPage);
