import React, { useEffect, useState } from "react";
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import logo from "../assets/icons/logo.svg";
import { useLocation } from "react-router-dom";
import Button from "../components/Button";

const ConfirmEmailPage = () => {
    const location = useLocation();
    const token = location?.state?.token;
    const [success, setSuccess]: any = useState(null);
    return (
        <AuthenticationLayout>
            <div className="max-w-[400px] w-full min-h-[400px] bg-white rounded-[20px]">
                <div className="py-5 px-10">
                    <img alt="" src={logo} className="mx-auto" />
                    <div className="flex flex-col text-center gap-y-8">
                        <h1 className="text-xl xl:text-2xl font-semibold">
                            Email Verification
                        </h1>
                        {success === true ? (
                            <div className="text-center">
                                <p className="text-base xl:text-lg text-primary mb-10">
                                    Email verification Successful!
                                </p>
                                <Button size="md" variant="secondary">
                                    Login
                                </Button>
                            </div>
                        ) : success === false ? (
                            <div className="text-center">
                                <p className="text-base xl:text-lg text-error mb-10">
                                    Email verification link is invalid or has
                                    expired!
                                </p>
                                <Button size="md" variant="secondary">
                                    Resend Link
                                </Button>
                            </div>
                        ) : (
                            <p className="text-base xl:text-lg text-primary/60">
                                A mail has been sent to your email address to
                                verify your account!
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </AuthenticationLayout>
    );
};

export default ConfirmEmailPage;
