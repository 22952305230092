import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import Table from "../components/TableComponent";
import { Form, Formik, FormikHelpers } from "formik";
import Button from "../components/Button";
import FormElement from "../components/FormElements";
import chevronDown from "../assets/icons/Chevron_Down.svg";
import closeIcon from "../assets/icons/close.svg";
import { exportExcel } from "../utils/exportData";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import { storeBulkInsert } from "../utils/storeHelper";
import Loader from "../components/Loader";
import { SERVER } from "../config/axios";
import axios from "axios";
import CancelSearch from "../components/CancelSearch";
import { handleAxiosError } from "../utils/errors";

interface Values {
    firstName: string;
    lastName: string;
    facilityType: string;
    category: string;
    riskLevel: string;
}

type DebtorsPagePropsType = {
    getDebtor: Function;
    authReducer: any;
};

const DebtorsPage = (props: DebtorsPagePropsType) => {
    const [loading, setLoading] = useState(false);
    const [debtors, setDebtors]: any = useState([]);
    const [pages, setPages]: any = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [facilityType, setFacilityType] = useState("");
    const [category, setCategory] = useState("");
    const [riskLevel, setRiskLevel] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const [pageNumber, setPageNumber] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);
    const prevPage = async () => {
        if (currentPage > 1) {
            setLoading(true);
            setTimeout(() => {
                setCurrentPage((currentPage) => currentPage - 1);
                setLoading(false);
            }, 1000);
        }
    };

    const nextPage = async () => {
        if (currentPage < totalPageCount) {
            setLoading(true);
            setTimeout(() => {
                setCurrentPage((currentPage) => currentPage + 1);
                setLoading(false);
            }, 1000);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        await SERVER.get("/debtors")
            .then(async ({ data }: any) => {
                // if (status === 200) {
                setDebtors(data?.data);
                console.log(data, "data data");
                // }
            })
            .catch((error) => handleAxiosError(error))
            .finally(() => setLoading(false));
    };

    const [toggleDownload, setToggleDownload] = useState(false);

    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(1);

    const filteredSearch = debtors?.filter((el: any) => {
        return (
            el && el?.firstName?.includes(firstName?.toLowerCase()) &&
            el?.lastName?.includes(lastName?.toLowerCase()) &&
            el?.riskLevel?.includes(riskLevel?.toLowerCase()) &&
            el.category?.toLowerCase().includes(category?.toLowerCase()) &&
            el.facilityType?.toLowerCase().includes(facilityType?.toLowerCase())
        );
    });

    const totalPageCount = Math.ceil(filteredSearch?.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSearch.slice(
        indexOfFirstItem,
        indexOfLastItem
    );

    const handleSearch = (values: Values) => {
        values.firstName && setFirstName(values.firstName);
        values.lastName && setLastName(values.lastName);
        values.facilityType && setFacilityType(values.facilityType);
        values.category && setCategory(values.category);
        values.riskLevel && setRiskLevel(values.riskLevel);
    };

    return (
        <>
            <DashboardLayout>
                <div className="grow">
                    <div className="pt-10">
                        <div className="p-5">
                            <Formik
                                initialValues={{
                                    firstName: "",
                                    lastName: "",
                                    facilityType: "",
                                    category: "",
                                    riskLevel: "",
                                }}
                                onSubmit={(
                                    values: Values,
                                    { setSubmitting }: FormikHelpers<Values>
                                ) => {
                                    console.log(values, "values");
                                    handleSearch(values);
                                }}
                            >
                                {({
                                    errors,
                                    touched,
                                    setFieldValue,
                                    submitForm,
                                }) => {
                                    return (
                                        <Form>
                                            <div className="container flex items-end justify-between w-full gap-x-5">
                                                <div className="grid grid-cols-5 gap-x-3">
                                                    <FormElement
                                                        type="text"
                                                        name="firstName"
                                                        id="firstName"
                                                        label="First Name"
                                                        placeholder="First Name"
                                                        error={
                                                            errors.firstName &&
                                                            touched.firstName
                                                                ? errors.firstName
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        type="text"
                                                        name="lastName"
                                                        id="lastName"
                                                        label="Last Name"
                                                        placeholder="Last Name"
                                                        error={
                                                            errors.lastName &&
                                                            touched.lastName
                                                                ? errors.lastName
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        type="select"
                                                        name="facilityType"
                                                        id="facilityType"
                                                        label="Facility Type"
                                                        options={[
                                                            "Term Loan",
                                                            "Overdraft",
                                                            "Sydicated Facility",
                                                            "Contract Finance",
                                                            "Invoice Discount",
                                                            "Mortgage Loans",
                                                            "Personal Loan",
                                                            "Others",
                                                        ]}
                                                        error={
                                                            errors.facilityType &&
                                                            touched.facilityType
                                                                ? errors.facilityType
                                                                : null
                                                        }
                                                        onClick={(field: any) =>
                                                            setFieldValue(
                                                                "facilityType",
                                                                field.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                    <FormElement
                                                        type="select"
                                                        name="category"
                                                        id="category"
                                                        label="Category"
                                                        options={[
                                                            "Financial Fraud",
                                                            "Financial Indebtedness",
                                                            "Drug Related Issues",
                                                            "Others",
                                                        ]}
                                                        error={
                                                            errors.category &&
                                                            touched.category
                                                                ? errors.category
                                                                : null
                                                        }
                                                        onClick={(field: any) =>
                                                            setFieldValue(
                                                                "category",
                                                                field.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                    <FormElement
                                                        type="select"
                                                        name="riskLevel"
                                                        id="riskLevel"
                                                        label="Risk Level"
                                                        options={[
                                                            "Low",
                                                            "Medium",
                                                            "High",
                                                            "Extremely High",
                                                        ]}
                                                        error={
                                                            errors.riskLevel &&
                                                            touched.riskLevel
                                                                ? errors.riskLevel
                                                                : null
                                                        }
                                                        onClick={(field: any) =>
                                                            setFieldValue(
                                                                "riskLevel",
                                                                field.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="flex items-center">
                                                    <CancelSearch />

                                                    <Button
                                                        size="md"
                                                        variant="secondary"
                                                        onClick={handleSearch}
                                                    >
                                                        Search
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>

                        <div className="flex items-center justify-between hover:cursor-pointer p-5 pt-14">
                            <h1 className="font-semibold text-2xl xl:text-[32px]">
                                Debtors
                            </h1>

                            <div>
                                <div
                                    className="flex items-center gap-x-3 relative"
                                    onClick={() => setToggleDownload(true)}
                                >
                                    <p className="font-semibold text-base xl:text-lg">
                                        Download as
                                    </p>
                                    <img alt="" src={chevronDown} />
                                </div>

                                {toggleDownload && (
                                    <div className="absolute bottom-0 right-0 w-[150px] -mb-5 h-[100px] bg-white-light rounded-lg z-[999] py-10 flex flex-col gap-8 shadow-md border border-primary">
                                        <img
                                            alt=""
                                            src={closeIcon}
                                            className="w-5 h-5 hover:cursor-pointer ml-auto absolute top-3 right-3"
                                            onClick={() =>
                                                setToggleDownload(false)
                                            }
                                        />

                                        <div
                                            className="px-7 hover:underline hover:cursor-pointer"
                                            onClick={() =>
                                                exportExcel(
                                                    filteredSearch,
                                                    "Debtors"
                                                )
                                            }
                                        >
                                            Excel
                                        </div>
                                        {/* <div className="px-7 hover:underline hover:cursor-pointer">
                                            CSV
                                        </div> */}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-5">
                            <Table
                                type="DEBTORS"
                                data={currentItems}
                                isPaginated
                                pageNumber={currentPage}
                                prevPage={prevPage}
                                nextPage={nextPage}
                                pages={totalPageCount}
                            />
                        </div>
                    </div>
                </div>
            </DashboardLayout>
            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = ({ authReducer }: any) => ({
    authReducer,
});

export default connect(mapStateToProps, actions)(DebtorsPage);
