import React from "react";
import logo from "../assets/icons/logo.svg";
import account from "../assets/icons/account.svg";
import logoutIcon from "../assets/icons/Log_Out.svg";
import qr from "../assets/icons/Qr_Code.svg";
import userCircle from "../assets/icons/UserCircle.svg";
import usersGroup from "../assets/icons/Users_Group.svg";
import chart from "../assets/icons/Chart_Line.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import store from "../store";
import * as TYPES from "../dtos";

type _props = {
    children?: React.ReactNode;
};

const DashboardLayout = (props: _props) => {
    const { user } = store.getState().authReducer;
    const role = user?.role === "SUPERADMIN";
    const { children } = props;
    const location = useLocation();
    const navigate = useNavigate();

    const navLinks1 = [
        {
            path: "/dashboard",
            name: "Dashboard",
            icon: qr,
        },
        {
            path: "/dashboard/debtors",
            name: "Debtors",
            icon: userCircle,
        },
        {
            path: "/dashboard/debtor-profiling",
            name: "Debtor Profiling",
            icon: userCircle,
        },
        // {
        //     path: "/dashboard/audit-trail",
        //     name: "Audit Trail",
        //     isSuperAdmin: true,
        //     icon: chart,
        // },
        // {
        //     path: "/dashboard/manage-users",
        //     name: "Manage Users",
        //     isSuperAdmin: true,
        //     icon: usersGroup,
        // },
        {
            path: "/dashboard/profile",
            name: "My Profile",
            icon: account,
        },
        {
            path: "/sign-in",
            name: "Logout",
            icon: logoutIcon,
        },
    ];

    const navLinks = [
        {
            path: "/dashboard",
            name: "Dashboard",
            icon: qr,
        },
        {
            path: "/dashboard/debtors",
            name: "Debtors",
            icon: userCircle,
        },
        {
            path: "/dashboard/debtor-profiling",
            name: "Debtor Profiling",
            icon: userCircle,
        },
        {
            path: "/dashboard/audit-trail",
            name: "Audit Trail",
            isSuperAdmin: true,
            icon: chart,
        },
        {
            path: "/dashboard/manage-users",
            name: "Manage Users",
            isSuperAdmin: true,
            icon: usersGroup,
        },
        {
            path: "/dashboard/profile",
            name: "My Profile",
            icon: account,
        },
        {
            path: "/sign-in",
            name: "Logout",
            icon: logoutIcon,
        },
    ];

    const filteredLink = !role ? navLinks1 : navLinks;

    const logoutUser = (path: string) => {
        if (path !== "/logout") {
            navigate(path);
            return;
        } else {
            store.dispatch({
                type: TYPES.REMOVE_AUTHENTICATION,
            });
            store.dispatch({
                type: TYPES.SET_USER,
                payload: null,
            });
            store.dispatch({
                type: TYPES.USER_LOGIN,
                payload: null,
            });
            store.dispatch({
                type: TYPES.SET_USERS,
                payload: [],
            });
            store.dispatch({
                type: TYPES.SET_DEBTORS,
                payload: [],
            });
            store.dispatch({
                type: TYPES.SET_ACTIVITIES,
                payload: [],
            });
        }
    };

    return (
        <div className="w-full min-h-screen bg-white flex">
            <div className="w-72 border-r border-primary py-8 flex flex-col gap-y-10 max-h-screen sticky top-0">
                <img alt="" src={logo} />
                <div className="grow xl:text-xl flex flex-col justify-between pr-4">
                    <div className="flex flex-col gap-y-4">
                        {filteredLink
                            .slice(0, 3)
                            .map(({ name, icon, path }, index) => (
                                <Link
                                    className={`flex items-center py-4 pr-4 transition ease-in-out delay-150 duration-300 hover:border hover:border-b-2 hover:border-r-2 hover:border-primary hover:rounded-r-lg ${
                                        location.pathname === path &&
                                        "bg-gray-dark border border-b-4 border-r-4 border-primary rounded-r-lg"
                                    }`}
                                    key={index}
                                    to={path}
                                >
                                    <img
                                        alt=""
                                        src={icon}
                                        className="ml-4 mr-8"
                                    />
                                    <span>{name}</span>
                                </Link>
                            ))}
                    </div>
                    <div className="flex flex-col gap-y-4">
                        {filteredLink
                            .slice(3)
                            .map(({ name, icon, path }, index) => (
                                <Link
                                    className={`flex items-center py-4 pr-4 transition ease-in-out delay-150 duration-300 hover:border hover:border-b-2 hover:border-r-2 hover:border-primary hover:rounded-r-lg ${
                                        location.pathname === path &&
                                        "bg-gray-dark border border-b-4 border-r-4 border-primary rounded-r-lg"
                                    }`}
                                    key={index}
                                    to=""
                                    onClick={() => logoutUser(path)}
                                >
                                    <img
                                        alt=""
                                        src={icon}
                                        className="ml-4 mr-8 w-6 h-6"
                                    />
                                    <span>{name}</span>
                                </Link>
                            ))}
                    </div>
                </div>
                {/* <div></div> */}
            </div>
            <div className="grow flex flex-col">{children}</div>
        </div>
    );
};

export default DashboardLayout;
