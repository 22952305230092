import React, { useState } from "react";
import chevronLeft from "../assets/icons/Chevron_Left.svg";
import chevronRight from "../assets/icons/Chevron_Right.svg";
import chevronDown from "../assets/icons/Chevron_Down.svg";
import menuIcon from "../assets/icons/More_Vertical.svg";
import closeIcon from "../assets/icons/close.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
interface tableProps {
    headers?: Array<string>;
    data?: Array<object>;
    isPaginated?: boolean;
    type?: "DEBTORS" | "TRAIL" | "USERS";
    loading?: boolean;
    onClickSuperAdmin?: any;
    onClickDeactivateUser?: any;
    user?: any;
    pageNumber?: number;
    nextPage?: any;
    prevPage?: any;
    isLastPage?: boolean;
    pages?: number;
    viewMoreTrail?: any;
}

const Table = (props: tableProps) => {
    const navigate = useNavigate();
    const {
        headers,
        data,
        isPaginated,
        type,
        loading,
        onClickDeactivateUser,
        onClickSuperAdmin,
        user,
        pageNumber = 1,
        nextPage,
        prevPage,
        isLastPage,
        pages,
        viewMoreTrail,
    } = props;
    const [selectedIndex, setSelectedIndex]: any = useState(null);
    const [paginate, setPaginate]: any = useState(1);
    return (
        <div className="relative bg-gray-dark rounded-3xl pb-10 px-3 flex flex-col items-center min-h-[500px] justify-between">
            {!loading && !data?.length && (
                <div className="text-center absolute mt-[200px] text-2xl font-semibold">
                    No records found
                </div>
            )}
            <table
                className="w-full table text-left text-primary"
                cellPadding={16}
            >
                <thead className="text-base xl:text-lg border-b-2 border-primary">
                    <tr>
                        {/* {headers?.map((item, index) => (
                            <th
                                scope="col"
                                className={`${
                                    headers.length - 1 === index
                                        ? "text-right"
                                        : "text-left"
                                }`}
                                key={index}
                            >
                                {item}
                            </th>
                        ))} */}

                        {type === "TRAIL" ? (
                            <>
                                <th scope="col">Full Name</th>
                                <th scope="col">Timestamp</th>
                                <th scope="col">Action</th>
                                <th scope="col">Initiator</th>
                                <th scope="col" className="text-right">
                                    Status
                                </th>
                            </>
                        ) : type === "DEBTORS" ? (
                            <>
                                <th scope="col">Full Name</th>
                                <th scope="col">Loan Amount</th>
                                <th scope="col">Category</th>
                                <th scope="col">Facility Type</th>
                                <th scope="col" className="text-right">
                                    Risk Level
                                </th>
                            </>
                        ) : type === "USERS" ? (
                            <>
                                <th scope="col">Full Name</th>
                                <th scope="col">Email Address</th>
                                <th scope="col">Role</th>
                                <th scope="col" className="text-right">
                                    Status
                                </th>
                            </>
                        ) : (
                            <>
                                <th scope="col">Timestamp</th>
                                <th scope="col">Activity Type</th>
                                <th scope="col" className="text-right">
                                    Status
                                </th>
                            </>
                        )}
                    </tr>
                </thead>

                <tbody className="text-xl">
                    {type === "TRAIL"
                        ? data?.map((item: any, index: number) => (
                              <tr key={index}>
                                  <td className="whitespace-nowrap capitalize">
                                      {`${item?.debtor?.firstName} ${item?.debtor?.lastName}`}
                                  </td>
                                  <td className="font-normal">
                                      <div className="flex gap-2">
                                          <span>
                                              {moment(item?.createdAt).format(
                                                  "DD/MM/YYYY"
                                              )}
                                          </span>
                                          <span>
                                              -
                                              {moment(item?.createdAt).format(
                                                  "h:mma"
                                              )}
                                          </span>
                                      </div>
                                  </td>
                                  <td className="font-normal">
                                      {item?.activityType}
                                  </td>
                                  <td className="font-normal">
                                      {item?.debtor?.user?.fullName}
                                  </td>
                                  <td
                                      className={`${
                                          item?.debtor?.user?.verified
                                              ? "text-[#2D8153]"
                                              : "text-[#EA001B]"
                                      } text-right`}
                                  >
                                      {item?.debtor?.user?.verified
                                          ? "Successful"
                                          : "Pending"}
                                  </td>
                              </tr>
                          ))
                        : type === "DEBTORS"
                        ? data?.map((item: any, index: number) => (
                              <tr
                                  key={index}
                                  onClick={() =>
                                      navigate("/dashboard/debtor-info", {
                                          state: {
                                              debtor: item,
                                              type: "debtor",
                                          },
                                      })
                                  }
                                  className="cursor-pointer"
                              >
                                  <td className="whitespace-nowrap capitalize">
                                      {`${item?.firstName} ${item?.lastName}`}
                                  </td>
                                  <td className="font-normal">
                                      {item?.loanAmount}
                                  </td>
                                  <td className="font-normal">
                                      {item?.category}
                                  </td>
                                  <td className="font-normal">
                                      {item?.facilityType}
                                  </td>
                                  <td
                                      className={`${
                                          item?.riskLevel?.toLowerCase() ===
                                          "low"
                                              ? "text-[#EC77FF]"
                                              : item?.riskLevel?.toLowerCase() ===
                                                "medium"
                                              ? "text-[#FE9B0A]"
                                              : item?.riskLevel?.toLowerCase() ===
                                                "high"
                                              ? "text-[#8B434B]"
                                              : "text-[#FF011E]"
                                      } text-right`}
                                  >
                                      {item?.riskLevel}
                                  </td>
                              </tr>
                          ))
                        : type === "USERS"
                        ? data?.map((item: any, index: number) => (
                              <tr
                                  key={index}
                                  //   onClick={() =>
                                  //       navigate("/dashboard/debtor-info", {
                                  //           state: { user: item, type: "user" },
                                  //       })
                                  //   }
                                  className="cursor-pointer"
                              >
                                  <td className="whitespace-nowrap capitalize">
                                      {item?.fullName}
                                  </td>
                                  <td className="font-normal">{item?.email}</td>
                                  <td className="font-normal">{item?.role}</td>
                                  <td className="flex justify-end relative">
                                      <div className="flex items-center -mr-3">
                                          <span>
                                              {item?.isDeactivated
                                                  ? "Inactive"
                                                  : "Active"}
                                          </span>
                                          {user?.role === "SUPERADMIN" && (
                                              <img
                                                  alt=""
                                                  src={menuIcon}
                                                  className="hover:cursor-pointer"
                                                  onClick={() =>
                                                      setSelectedIndex(index)
                                                  }
                                              />
                                          )}
                                      </div>

                                      {index === selectedIndex && (
                                          <div
                                              className={`absolute ${
                                                  index === data?.length - 1
                                                      ? "bottom-0"
                                                      : "top-0"
                                              } right-0 w-[224px] h-[239px] bg-white-light rounded-lg z-10 py-10 flex flex-col gap-8 shadow-md`}
                                          >
                                              <img
                                                  alt=""
                                                  src={closeIcon}
                                                  className="w-5 h-5 hover:cursor-pointer ml-auto absolute top-3 right-3"
                                                  onClick={() =>
                                                      setSelectedIndex(null)
                                                  }
                                              />

                                              <div
                                                  className="px-7 hover:underline hover:cursor-pointer"
                                                  onClick={() => {
                                                      let newRole =
                                                          item?.role === "ADMIN"
                                                              ? "SUPERADMIN"
                                                              : "ADMIN";
                                                      onClickSuperAdmin(
                                                          item?._id,
                                                          newRole
                                                      );
                                                  }}
                                              >
                                                  {item?.role === "ADMIN"
                                                      ? "Make super admin"
                                                      : "Make  admin"}
                                              </div>
                                              <div
                                                  className="px-7 hover:underline hover:cursor-pointer"
                                                  onClick={(e) => {
                                                      onClickDeactivateUser(
                                                          item?._id
                                                      );
                                                  }}
                                              >
                                                  {item?.isDeactivated
                                                      ? "Activate "
                                                      : "Deactivate "}
                                                  user
                                              </div>
                                          </div>
                                      )}
                                  </td>
                              </tr>
                          ))
                        : data?.map((item: any, index) => (
                              <tr key={index}>
                                  <td className="whitespace-nowrap">
                                      {item?.createdAt}
                                  </td>
                                  <td className="font-normal">
                                      {item?.activityType}
                                  </td>
                                  <td
                                      className={`${
                                          item?.status?.toLowerCase() ===
                                          "approved"
                                              ? "text-[#2D8153]"
                                              : "text-[#EA001B]"
                                      } text-right`}
                                  >
                                      {item?.status}
                                  </td>
                              </tr>
                          ))}
                </tbody>
            </table>
            <div className="w-full">
                {!isPaginated && type ? (
                    type !== "TRAIL" && (
                        <div className="text-right p-5 pb-0 font-semibold text-base xl:text-lg">
                            <span
                                className="cursor-pointer"
                                onClick={() => navigate("/dashboard/debtors")}
                            >
                                View More
                            </span>
                        </div>
                    )
                ) : !isPaginated && !type ? (
                    <div className="flex justify-end p-5 pb-0">
                        <div
                            className="flex items-center gap-x-3"
                            onClick={() => alert("working")}
                        >
                            <p className="font-semibold text-base xl:text-lg">
                                Download as
                            </p>
                            <img alt="" src={chevronDown} />
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="border-primary border rounded-l-2xl rounded-r-2xl w-[213px] h-[48px] mx-auto mt-9 flex items-center justify-between px-4">
                            <span
                                className="hover:cursor-pointer"
                                onClick={() => pageNumber > 1 && prevPage()}
                            >
                                <img alt="" src={chevronLeft} />
                            </span>
                            <span className="text-sm">
                                {data?.length &&
                                    (data?.length * pageNumber) /
                                        data?.length}{" "}
                                OF {pages}
                            </span>
                            <span
                                className="hover:cursor-pointer"
                                onClick={() => !isLastPage && nextPage()}
                            >
                                <img alt="" src={chevronRight} />
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Table;
