import { Navigate, createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../views/Home";
import ErrorPage from "../views/ErrorPage";
import SigninPage from "../views/SigninPage";
import SignupPage from "../views/SignupPage";
import ForgotPasswordPage from "../views/ForgotPassword";
import ConfirmEmailPage from "../views/ConfirmEmail";
import DebtorProfilingPage from "../views/DebtorProfilingPage";
import AuditTrailPage from "../views/AuditTrailPage";
import ManageUsersPage from "../views/ManageUsersPage";
import ProfilePage from "../views/ProfilePage";
import { ProtectedRoute } from "../components/ProtectedRoute";
import DashboardScreen from "../views/DashboardScreen";
import DebtorsPage from "../views/DebtorsPage";
import VerifyEmailScreen from "../views/VerifyEmailScreen";
import VerifyTokenScreen from "../views/VerifyTokenScreen";
import DebtorInfo from "../views/DebtorInfo";
import ResetPasswordPage from "../views/ResetPassword";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                element: <Navigate to="/dashboard" />,
                index: true,
            },
            {
                path: "sign-in",
                element: <SigninPage />,
            },
            {
                path: "sign-up",
                element: <SignupPage />,
            },
            {
                path: "forgot-password",
                element: <ForgotPasswordPage />,
            },
            {
                path: "reset-password/:token",
                element: <ResetPasswordPage />,
            },
            {
                path: "confirm-email",
                element: <ConfirmEmailPage />,
            },
            {
                path: "verify-email",
                element: <VerifyEmailScreen />,
            },
            {
                path: "verify-token/:token",
                element: <VerifyTokenScreen />,
            },
            {
                path: "dashboard",
                element: (
                    <ProtectedRoute>
                        <DashboardScreen />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        path: "debtor-profiling",
                        element: <DebtorProfilingPage />,
                    },
                    {
                        path: "debtor-info",
                        element: <DebtorInfo />,
                    },
                    {
                        path: "debtors",
                        element: <DebtorsPage />,
                    },
                    {
                        path: "audit-trail",
                        element: <AuditTrailPage />,
                    },
                    {
                        path: "manage-users",
                        element: <ManageUsersPage />,
                    },
                    {
                        path: "profile",
                        element: <ProfilePage />,
                    },
                    {
                        index: true,
                        element: <Home />,
                    },
                ],
            },
            {
                path: "logout",
                loader: async () => {
                    return;
                },
            },
        ],
    },
]);

export default router;
