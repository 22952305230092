import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { authReducer } from "./reducers/authReducer";
import localStorage from "redux-persist/es/storage";

const persistConfig = {
    key: "root",
    storage: localStorage,
    whitelist: ["authReducer", "savingsReducer"],
};

const customMiddleWare = () => (next: any) => (action: any) => {
    next(action);
};

const reducers = combineReducers({
    authReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
    persistedReducer,
    compose(applyMiddleware(thunk, customMiddleWare))
);

export const persistor = persistStore(store);

export default store;
