import * as xlsx from "xlsx";

function exportExcel(data: any, type: string) {
    const worksheet = xlsx.utils.json_to_sheet(data);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    xlsx.writeFile(workbook, `${type + "-" + Date.now()}.xlsx`);
}

export { exportExcel };
