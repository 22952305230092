import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import DashboardLayout from "../layouts/DashboardLayout";
import NavAccount from "../components/NavAccount";
import account from "../assets/icons/account.svg";
import Button from "../components/Button";
import FormElement from "../components/FormElements";
import { Formik, FormikHelpers } from "formik";
import { Form } from "react-router-dom";
import Table from "../components/TableComponent";
import editIcon from "../assets/icons/Edit.svg";
import closeIcon from "../assets/icons/close.svg";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import { storeBulkInsert } from "../utils/storeHelper";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { handleAxiosError } from "../utils/errors";
import { SERVER } from "../config/axios";
import CancelSearch from "../components/CancelSearch";
import { Link, animateScroll as scroll, scrollSpy } from "react-scroll";
interface Values {
    startDate: string;
    endDate: string;
    activityType: string;
    firstName: string;
}

interface PasswordValues {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

type ProfilePagePropsType = {
    authReducer: any;
    getActivities: Function;
    changePassword: Function;
};

const ProfilePageSchema = Yup.object().shape({
    oldPassword: Yup.string().min(1, "Too Short!").required("Required"),
    newPassword: Yup.string().min(1, "Too Short!").required("Required"),
    confirmPassword: Yup.string().min(1, "Too Short!").required("Required"),
});

const ProfilePage = (props: ProfilePagePropsType) => {
    const { authReducer, getActivities, changePassword } = props;
    const { user, activities } = authReducer;
    const [loading, setLoading] = useState(false);
    const [audits, setAudits]: any = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [activityType, setActivityType] = useState("");
    const [firstName, setFirstName] = useState("");
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [showModal2, setShowModal2] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const filteredSearch = audits?.filter((el: any) => {
        return (
            el?.debtor.firstName
                ?.toLowerCase()
                .includes(firstName?.toLowerCase()) &&
            el?.activityType
                ?.toLowerCase()
                .includes(activityType?.toLowerCase())
        );
    });

    const totalPageCount = Math.ceil(filteredSearch?.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSearch.slice(
        indexOfFirstItem,
        indexOfLastItem
    );

    const prevPage = async () => {
        if (currentPage > 1) {
            setLoading(true);
            setTimeout(() => {
                setCurrentPage((currentPage) => currentPage - 1);
                setLoading(false);
            }, 1000);
        }
    };

    const nextPage = async () => {
        if (currentPage < totalPageCount) {
            setLoading(true);
            setTimeout(() => {
                setCurrentPage((currentPage) => currentPage + 1);
                setLoading(false);
            }, 1000);
        }
    };

    // const handleGetAudits = useCallback(async () => {
    //     setLoading(true);
    //     await getActivities()
    //         .then(async ({ status, data }: any) => {
    //             if (status === 200) {
    //                 setAudits(data?.data);
    //             }
    //         })
    //         .catch(async (error: any) => {
    //             handleAxiosError(error);
    //         })
    //         .finally(() => setLoading(false));
    // }, [getActivities]);

    const fetchData = async () => {
        setLoading(true);
        await SERVER.get("/activities", {
            params: {
                userActivity: true,
            },
        })
            .then(async ({ status, data }: any) => {
                if (status === 200) {
                    setAudits(data?.data);
                }
            })
            .catch((error) => handleAxiosError(error))
            .finally(() => {
                setLoading(false);
            });
    };

    const viewMoreTrail = (val: string) => {
        alert(val);
    };

    const handleResetPassword = useCallback(
        async (payload: any) => {
            setLoading(true);
            await changePassword({ ...payload, userId: user?._id })
                .then(async ({ status, data }: any) => {
                    console.log(data?.data, "data?.data handleResetPassword");
                    if (status === 200) {
                        setShowModal(false);
                        toast.success("Password updated");
                    }
                })
                .catch(async (error: any) => {
                    handleAxiosError(error);
                })
                .finally(() => setLoading(false));
        },
        [changePassword, user?._id]
    );

    const [showModal, setShowModal] = useState(false);

    const handleSearch = (values: any) => {
        values.startDate && setStartDate(values.startDate);

        values.endDate && setEndDate(values.endDate);

        values.activityType && setActivityType(values.activityType);

        values.firstName && setFirstName(values.firstName);

        // if (values.startDate || values.endDate || values.activityType) {
        //     SERVER.get(
        //         `/activities?${
        //             values.startDate &&
        //             `createdAt={$gte: ${new Date(values.startDate)}}&`
        //         }${
        //             values.endDate &&
        //             `createdAt={$lte: ${new Date(values.endDate)}}&`
        //         }${
        //             values.activityType &&
        //             `activityType=${values.activityType}&`
        //         }`
        //     )
        //         .then(({ data }: any) => {
        //             console.log(data, "data");
        //             setAudits(data?.data);
        //         })
        //         .catch((err) => console.log(err));
        // }
    };

    return (
        <>
            <DashboardLayout>
                <div className="grow">
                    <div className="p-5">
                        <div className="w-full pt-10 flex justify-between items-center">
                            <h3 className="text-2xl xl:text-[32px] font-semibold">
                                My Profile
                            </h3>
                            <NavAccount />
                        </div>

                        <div className="flex items-start mt-10 gap-x-8 mb-16">
                            <div className="rounded-full border-b-8 border-b-primary border-r-8 border-r-primary w-[200px] h-[200px]">
                                <img
                                    alt=""
                                    src={account}
                                    className="object-center object-cover w-full h-full"
                                />
                            </div>

                            <div className="table min-w-[290px] mt-6">
                                <div className="table-row-group gap-y-10 text-lg xl:text-[20px]">
                                    <div className="table-row">
                                        <div className="table-cell p-2.5 font-semibold">
                                            Full Name:
                                        </div>
                                        <div className="table-cell p-2.5">
                                            {user?.fullName}
                                        </div>
                                    </div>
                                    <div className="table-row">
                                        <div className="table-cell p-2.5 font-semibold">
                                            Email:
                                        </div>
                                        <div className="table-cell p-2.5">
                                            {user?.email}
                                        </div>
                                    </div>
                                    <div className="table-row">
                                        <div className="table-cell p-2.5 font-semibold">
                                            Role:
                                        </div>
                                        <div className="table-cell p-2.5">
                                            {user?.role}
                                        </div>
                                    </div>
                                    <div className="table-row">
                                        <div className="table-cell p-2.5 font-semibold">
                                            Password:
                                        </div>
                                        <div className="table-cell p-2.5">
                                            <div className="flex items-center gap-x-3">
                                                <img
                                                    alt=""
                                                    src={editIcon}
                                                    className="hover:cursor-pointer"
                                                    onClick={() =>
                                                        setShowModal(true)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3 className="text-2xl xl:text-[32px] font-semibold mb-4">
                                Activities
                            </h3>
                            <Formik
                                initialValues={{
                                    startDate: "",
                                    endDate: "",
                                    activityType: "",
                                    firstName: "",
                                }}
                                onSubmit={(
                                    values: Values,
                                    { setSubmitting }: FormikHelpers<Values>
                                ) => {
                                    handleSearch(values);
                                }}
                            >
                                {({
                                    errors,
                                    touched,
                                    setFieldValue,
                                    submitForm,
                                }) => {
                                    return (
                                        <Form>
                                            <div className="container flex items-end justify-between">
                                                <div className="grid grid-cols-3 gap-x-[50px] w-full max-w-3xl">
                                                    {/* <FormElement
                                                        type="date"
                                                        name="startDate"
                                                        id="startDate"
                                                        label="Start Date"
                                                        error={
                                                            errors.startDate &&
                                                            touched.startDate
                                                                ? errors.startDate
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        type="date"
                                                        name="endDate"
                                                        id="endDate"
                                                        label="End Date"
                                                        error={
                                                            errors.endDate &&
                                                            touched.endDate
                                                                ? errors.endDate
                                                                : null
                                                        }
                                                    /> */}
                                                    <FormElement
                                                        type="text"
                                                        name="firstName"
                                                        id="firstName"
                                                        label="Full Name"
                                                        placeholder="Full Name"
                                                        error={
                                                            errors.firstName &&
                                                            touched.firstName
                                                                ? errors.firstName
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        type="select"
                                                        name="activityType"
                                                        id="activityType"
                                                        label="Activity Type"
                                                        options={[
                                                            "ADD DEBTOR",
                                                            "DELETE USER",
                                                            "UPGRADE",
                                                        ]}
                                                        onClick={(field: any) =>
                                                            setFieldValue(
                                                                "activityType",
                                                                field.target
                                                                    .value
                                                            )
                                                        }
                                                        error={
                                                            errors.activityType &&
                                                            touched.activityType
                                                                ? errors.activityType
                                                                : null
                                                        }
                                                    />
                                                </div>
                                                <div className="flex items-center">
                                                    <CancelSearch />
                                                    <Button
                                                        onClick={() =>
                                                            submitForm()
                                                        }
                                                        type="button"
                                                        size="md"
                                                        variant="secondary"
                                                    >
                                                        Search
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>

                            <div className="my-10">
                                {showModal2 ? (
                                    <Table
                                        type="TRAIL"
                                        data={currentItems}
                                        pages={totalPageCount}
                                        pageNumber={currentPage}
                                        isPaginated
                                        // isLastPage={isLastPage}
                                        nextPage={nextPage}
                                        prevPage={prevPage}
                                        viewMoreTrail={() => alert("hello")}
                                    />
                                ) : (
                                    <>
                                        <Table
                                            type="TRAIL"
                                            data={currentItems}
                                        />
                                        <div className="text-right p-5 pb-0 font-semibold text-base xl:text-lg">
                                            <span
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    setShowModal2(true)
                                                }
                                            >
                                                View More
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
            {showModal && (
                <div className="fixed w-screen h-screen top-0 left-0 bg-primary/[18%] z-10 flex items-center justify-center">
                    <div className="w-[770px] h-[550px] bg-white-light shadow-sm rounded-[20px] relative">
                        <img
                            alt=""
                            src={closeIcon}
                            className="absolute top-8 right-7 hover:cursor-pointer z-50"
                            onClick={() => setShowModal(false)}
                        />

                        <Formik
                            initialValues={{
                                oldPassword: "",
                                newPassword: "",
                                confirmPassword: "",
                            }}
                            onSubmit={(values: PasswordValues) => {
                                handleResetPassword(values);
                            }}
                            validationSchema={ProfilePageSchema}
                        >
                            {({ errors, touched, submitForm }) => {
                                return (
                                    <Form>
                                        <div className="pt-12">
                                            <h2 className="font-semibold text-center text-xl xl:text-[32px] mb-9">
                                                Change Password
                                            </h2>
                                            <div className="flex flex-col gap-y-5 w-[334px] mx-auto">
                                                <FormElement
                                                    type="password"
                                                    name="oldPassword"
                                                    label="Old Password"
                                                    labelFor="oldPassword"
                                                    placeholder="Old Password"
                                                    error={
                                                        errors.oldPassword &&
                                                        touched.oldPassword
                                                            ? errors.oldPassword
                                                            : null
                                                    }
                                                />
                                                <FormElement
                                                    type="password"
                                                    name="newPassword"
                                                    label="New Password"
                                                    labelFor="newPassword"
                                                    placeholder="New password"
                                                    error={
                                                        errors.newPassword &&
                                                        touched.newPassword
                                                            ? errors.newPassword
                                                            : null
                                                    }
                                                />
                                                <FormElement
                                                    type="password"
                                                    name="confirmPassword"
                                                    label="Confirm Password"
                                                    labelFor="confirmPassword"
                                                    placeholder="Confirm password"
                                                    error={
                                                        errors.confirmPassword &&
                                                        touched.confirmPassword
                                                            ? errors.confirmPassword
                                                            : null
                                                    }
                                                />
                                                <div>
                                                    <Button
                                                        onClick={() =>
                                                            submitForm()
                                                        }
                                                        type="button"
                                                        variant="secondary"
                                                        size="full"
                                                    >
                                                        Proceed
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            )}
            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = ({ authReducer }: any) => ({
    authReducer,
});

export default connect(mapStateToProps, actions)(ProfilePage);
