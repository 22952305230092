import React, { useCallback, useEffect, useState } from "react";
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import logo from "../assets/icons/logo.svg";

import Button from "../components/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import Loader from "../components/Loader";
import { SERVER } from "../config/axios";
import { handleAxiosError } from "../utils/errors";

function getQueryVariable(variable: string) {
    var query = window.location.search.substring(1);
    console.log(query); //"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
}

const VerifyTokenScreen = (props: any) => {
    const { verifyEmail } = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState("");
    const params: any = useParams();

    let token = params.token;

    console.log(token, "token");

    const handleVerifyToken = useCallback(async () => {
        setLoading(true);
        await verifyEmail({ token })
            .then(async ({ status, data }: any) => {
                if (status === 200) {
                    setSuccess("Your email have been verified successfully");
                }
            })
            .catch(async (error: any) => {
                setSuccess("");
                handleAxiosError(error);
            })
            .finally(() => setLoading(false));
    }, [token, verifyEmail]);

    useEffect(() => {
        handleVerifyToken();
    }, [handleVerifyToken, token]);

    return (
        <AuthenticationLayout>
            {loading && <Loader />}
            <div className="max-w-[450px] w-full min-h-[400px] bg-white rounded-[20px]">
                <div className="py-5 px-10">
                    <img alt="" src={logo} className="mx-auto" />

                    <div className="text-center">
                        <h3 className="text-2xl xl:text-[22px] font-semibold mt-5">
                            Welcome
                        </h3>
                        <p
                            className={`text-1xl xl:text-[16px] font-normal mt-5`}
                        >
                            {success}
                        </p>
                        <p className="text-1xl xl:text-[16px] font-normal mt-5 mb-10">
                            Proceed to login
                        </p>
                        <Button
                            onClick={() => navigate("/sign-in")}
                            type="submit"
                            variant="secondary"
                            size="md"
                        >
                            Login
                        </Button>
                    </div>
                </div>
            </div>
        </AuthenticationLayout>
    );
};

const mapStateToProps = ({ authReducer }: any) => ({
    authReducer,
});

export default connect(mapStateToProps, actions)(VerifyTokenScreen);
