import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import Table from "../components/TableComponent";
import { Form, Formik, FormikHelpers } from "formik";
import Button from "../components/Button";
import FormElement from "../components/FormElements";
import NavAccount from "../components/NavAccount";
import chevronDown from "../assets/icons/Chevron_Down.svg";
import closeIcon from "../assets/icons/close.svg";
import { exportExcel } from "../utils/exportData";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import { storeBulkInsert } from "../utils/storeHelper";
import Loader from "../components/Loader";
import { SERVER } from "../config/axios";
import CancelSearch from "../components/CancelSearch";
import { handleAxiosError } from "../utils/errors";

interface Values {
    startDate: string;
    endDate: string;
    activityType: string;
    fullName: string;
}
//

type AuditTrailPagePropsType = {
    authReducer: any;
    getActivities: Function;
};

const AuditTrailPage = (props: AuditTrailPagePropsType) => {
    const {
        getActivities,
        authReducer: { activities },
    } = props;
    const [loading, setLoading] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [audits, setAudits]: any = useState([]);
    const [pages, setPages]: any = useState(null);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [activityType, setActivityType] = useState("");
    const [fullName, setFullName] = useState("");
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(1);

    useEffect(() => {
        fetchData();
    }, []);

    const filteredSearch = audits?.filter((el: any) => {
        return (
            el?.debtor.firstName
                ?.toLowerCase()
                .includes(fullName?.toLowerCase()) &&
            el?.activityType
                ?.toLowerCase()
                .includes(activityType?.toLowerCase())
        );
    });

    const totalPageCount = Math.ceil(filteredSearch?.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSearch.slice(
        indexOfFirstItem,
        indexOfLastItem
    );

    const fetchData = async () => {
        setLoading(true);
        await SERVER.get("/activities")
            .then(async ({ status, data }: any) => {
                if (status === 200) {
                    setAudits(data?.data);
                }
            })
            .catch((error) => handleAxiosError(error))
            .finally(() => setLoading(false));
    };

    const [toggleDownload, setToggleDownload] = useState(false);

    const prevPage = async () => {
        if (currentPage > 1) {
            setLoading(true);
            setTimeout(() => {
                setCurrentPage((currentPage) => currentPage - 1);
                setLoading(false);
            }, 1000);
        }
    };

    const nextPage = async () => {
        if (currentPage < totalPageCount) {
            setLoading(true);
            setTimeout(() => {
                setCurrentPage((currentPage) => currentPage + 1);
                setLoading(false);
            }, 1000);
        }
    };

    const handleSearch = (values: Values) => {
        values.startDate && setStartDate(values.startDate);

        values.endDate && setEndDate(values.endDate);

        values.activityType && setActivityType(values.activityType);
        values.fullName && setFullName(values.fullName);

        console.log(values, "values");
    };

    return (
        <>
            <DashboardLayout>
                <div className="grow">
                    <div className="pt-10">
                        <div className="p-5 flex justify-between items-center">
                            <Formik
                                initialValues={{
                                    startDate: "",
                                    endDate: "",
                                    activityType: "",
                                    fullName: "",
                                }}
                                onSubmit={(
                                    values: Values,
                                    { setSubmitting }: FormikHelpers<Values>
                                ) => {
                                    console.log(values, "values");
                                    handleSearch(values);
                                }}
                            >
                                {({
                                    errors,
                                    touched,
                                    setFieldValue,
                                    submitForm,
                                }) => {
                                    return (
                                        <Form>
                                            <div className="container flex items-end justify-between w-full gap-x-[35px]">
                                                <div className="grid grid-cols-3 gap-x-[25px]">
                                                    {/* <FormElement
                                                        type="date"
                                                        name="startDate"
                                                        id="startDate"
                                                        label="Start Date"
                                                        error={
                                                            errors.startDate &&
                                                            touched.startDate
                                                                ? errors.startDate
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        type="date"
                                                        name="endDate"
                                                        id="endDate"
                                                        label="End Date"
                                                        error={
                                                            errors.endDate &&
                                                            touched.endDate
                                                                ? errors.endDate
                                                                : null
                                                        }
                                                    /> */}
                                                    <FormElement
                                                        type="text"
                                                        name="fullName"
                                                        id="fullName"
                                                        label="Full Name"
                                                        placeholder="Full Name"
                                                        error={
                                                            errors.fullName &&
                                                            touched.fullName
                                                                ? errors.fullName
                                                                : null
                                                        }
                                                    />
                                                    <FormElement
                                                        type="select"
                                                        name="activityType"
                                                        id="activityType"
                                                        label="Activity Type"
                                                        options={[
                                                            "ADD DEBTOR",
                                                            "DELETE USER",
                                                            "UPGRADE",
                                                        ]}
                                                        error={
                                                            errors.activityType &&
                                                            touched.activityType
                                                                ? errors.activityType
                                                                : null
                                                        }
                                                        onClick={(field: any) =>
                                                            setFieldValue(
                                                                "activityType",
                                                                field.target
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <div className="flex items-center">
                                                    <CancelSearch />
                                                    <Button
                                                        type="submit"
                                                        size="md"
                                                        variant="secondary"
                                                    >
                                                        Search
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>

                            <div>
                                <NavAccount />
                            </div>
                        </div>

                        <div className="flex items-center justify-between hover:cursor-pointer p-5 pt-14">
                            <h1 className="font-semibold text-2xl xl:text-[32px]">
                                AuditTrail
                            </h1>

                            <div>
                                <div
                                    className="flex items-center gap-x-3 relative"
                                    onClick={() => setToggleDownload(true)}
                                >
                                    <p className="font-semibold text-base xl:text-lg">
                                        Download as
                                    </p>
                                    <img alt="" src={chevronDown} />
                                </div>

                                {toggleDownload && (
                                    <div className="absolute bottom-0 right-0 w-[150px] -mb-5 h-[100px] bg-white-light rounded-lg z-[999] py-10 flex flex-col gap-8 shadow-md border border-primary">
                                        <img
                                            alt=""
                                            src={closeIcon}
                                            className="w-5 h-5 hover:cursor-pointer ml-auto absolute top-3 right-3"
                                            onClick={() =>
                                                setToggleDownload(false)
                                            }
                                        />

                                        <div
                                            className="px-7 hover:underline hover:cursor-pointer"
                                            onClick={() =>
                                                exportExcel(
                                                    filteredSearch,
                                                    "Audit"
                                                )
                                            }
                                        >
                                            Excel
                                        </div>
                                        {/* <div className="px-7 hover:underline hover:cursor-pointer">
                                            CSV
                                        </div> */}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="p-5">
                            <Table
                                type="TRAIL"
                                data={currentItems}
                                pages={totalPageCount}
                                pageNumber={currentPage}
                                isPaginated
                                // isLastPage={isLastPage}
                                nextPage={nextPage}
                                prevPage={prevPage}
                            />
                        </div>
                    </div>
                </div>
            </DashboardLayout>
            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = ({ authReducer }: any) => ({
    authReducer,
});

export default connect(mapStateToProps, actions)(AuditTrailPage);
