import { Dispatch } from "redux";
import { SERVER } from "../../config/axios";

export const loginUser = (payload: any) => async (_dispatch: Dispatch) => {
    return SERVER.post("/users/signin", payload);
};

export const registerUser = (payload: any) => async (_dispatch: Dispatch) => {
    return SERVER.post("/users/signup", payload);
};

export const fetchUsers =
    (keyword: string, pageNumber: number) => async (_dispatch: Dispatch) => {
        return SERVER.get(`/users`);
    };

export const makeSuperAdmin =
    (id: string, role: string) => async (_dispatch: Dispatch) => {
        return SERVER.patch(`/users/admin/${id}`, {
            role,
        });
    };

export const resetPassword = (payload: any) => async (_dispatch: Dispatch) => {
    return SERVER.post("/users/reset-password", payload);
};

export const changePassword = (payload: any) => async (_dispatch: Dispatch) => {
    console.log(payload, "payload");
    return SERVER.patch("/users/change-password", payload);
};

export const sendVerificationMail =
    (payload: any) => async (_dispatch: Dispatch) => {
        console.log(payload, "payload eeeee");
        return SERVER.post("/users/send-verification-email", payload);
    };

export const forgotPassword = (email: any) => async (_dispatch: Dispatch) => {
    return SERVER.post("/users/forgot-password", email);
};

export const verifyEmail = (token: any) => async (_dispatch: Dispatch) => {
    return SERVER.post("/users/verify-email", token);
};

export const deactivateUser = (id: string) => async (_dispatch: Dispatch) => {
    return SERVER.put(`/users/${id}`);
};

export const addDebtor = (payload: string) => async (_dispatch: Dispatch) => {
    return SERVER.post("/debtors", payload);
};

export const getActivities = () => async (_dispatch: Dispatch) => {
    return SERVER.get("/activities");
};

export const getDebtor = () => async (_dispatch: Dispatch) => {
    return SERVER.get("/debtors");
};

export const logOut = () => async (_dispatch: Dispatch) => {
    return localStorage.deleteItem("persist:root");
};
