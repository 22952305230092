import React from "react";
import { Outlet } from "react-router-dom";

const DashboardScreen = () => {
    return (
        <div className="w-screen h-screen">
            <Outlet />
        </div>
    );
};

export default DashboardScreen;
