import React from "react";

type _props = {
    children?: React.ReactNode;
    size?: "full" | "md";
    variant?: "light" | "secondary";
    type?: any;
    onClick?: any;
    disabled?: boolean;
};

const Button = (props: _props) => {
    const { children, variant, size, disabled } = props;
    function variantProp() {
        return variant === "light"
            ? "text-primary bg-white-lightest"
            : variant === "secondary"
            ? "text-primary bg-gray-dark border-2 border-b-4 border-r-4 border-primary"
            : "text-gray bg-primary";
    }
    function sizeProp() {
        return size === "full"
            ? "w-full"
            : size === "md"
            ? "w-[180px]"
            : "w-fit";
    }
    return (
        <button
            className={`text-lg xl:text-xl font-semibold h-10 xl:h-14 rounded-lg hover:opacity-80 transition ease-in-out delay-150 duration-300 ${
                disabled
                    ? "hover:opacity-80"
                    : "hover:opacity-70 opacity-70 cursor-default"
            } ${variantProp()} ${sizeProp()}`}
            {...props}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;
