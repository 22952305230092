import React, { useState } from "react";
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import logo from "../assets/icons/logo.svg";
import * as Yup from "yup";
import { Formik, Form, FormikHelpers } from "formik";
import Button from "../components/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormElement from "../components/FormElements";
import { SERVER } from "../config/axios";
import Loader from "../components/Loader";
import { handleAxiosError } from "../utils/errors";
import { toast } from "react-toastify";

interface Values {
    password: string;
    confirmPassword: string;
}

const ResetPasswordPage = () => {
    const params: any = useParams();
    const navigate = useNavigate();
    console.log(params, "params");
    const [loading, setLoading] = useState(false);

    const handleResetPassword = async (values: any) => {
        setLoading(true);
        try {
            const { status } = await SERVER.post("/users/reset-password", {
                token: params.token,
                ...values,
            });

            if (status === 200) {
                toast.success("Reset password successful");
                navigate("/sign-in");
            }
        } catch (error) {
            handleAxiosError(error);
        }
        setLoading(false);
    };

    const resetPasswordPageSchema = Yup.object().shape({
        password: Yup.string().min(8, "Too Short!").required("Required"),
        confirmPassword: Yup.string().min(8, "Too Short!").required("Required"),
    });

    return (
        <AuthenticationLayout>
            {loading && <Loader />}
            <div className="max-w-[530px] w-full min-h-[530px] bg-white rounded-[20px]">
                <div className="py-5 px-10">
                    <img alt="" src={logo} className="mx-auto" />

                    <Formik
                        initialValues={{
                            password: "",
                            confirmPassword: "",
                        }}
                        onSubmit={(values: Values) => {
                            handleResetPassword(values);
                        }}
                        validationSchema={resetPasswordPageSchema}
                    >
                        {({ errors, touched }) => {
                            return (
                                <Form className="flex flex-col space-y-10 pt-10 text-lg xl:text-xl max-w-[350px] mx-auto text-primary">
                                    <div className="flex flex-col text-center gap-y-8">
                                        <h1 className="text-xl xl:text-2xl font-semibold">
                                            Reset Password
                                        </h1>
                                        <p className="text-base xl:text-lg text-primary/60">
                                            Kindly create a new password
                                        </p>
                                    </div>
                                    <FormElement
                                        type="password"
                                        name="password"
                                        label="New Password"
                                        labelFor="password"
                                        placeholder="New Password"
                                        error={
                                            errors.password && touched.password
                                                ? errors.password
                                                : null
                                        }
                                    />
                                    <FormElement
                                        type="password"
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        labelFor="confirmPassword"
                                        placeholder="Password"
                                        error={
                                            errors.confirmPassword &&
                                            touched.confirmPassword
                                                ? errors.confirmPassword
                                                : null
                                        }
                                    />

                                    <div className="text-center">
                                        <Button
                                            type="submit"
                                            variant="secondary"
                                            size="md"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                    <p className="text-primary/60 text-center">
                                        Go back to{" "}
                                        <Link
                                            to="/sign-in"
                                            className="text-primary font-semibold"
                                        >
                                            Sign In
                                        </Link>
                                    </p>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </AuthenticationLayout>
    );
};

export default ResetPasswordPage;
