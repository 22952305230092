import React from "react";
import account from "../assets/icons/account.svg";
import store from "../store";

const NavAccount = (props: any) => {
    const { user } = store.getState().authReducer;
    return (
        <div className="flex items-center gap-x-3">
            <img alt="" src={account} className="w-6 h-6" />
            <p className="text-base xl:text-lg">
                Hi, {user?.fullName?.split(" ")[0]}
            </p>
        </div>
    );
};

export default NavAccount;
