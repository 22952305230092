import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import FormElement from "../components/FormElements";
import { Form, Formik, FormikHelpers } from "formik";
import searchIcon from "../assets/icons/search.svg";
import plus from "../assets/icons/plus.svg";
import { useNavigate } from "react-router-dom";
import NavAccount from "../components/NavAccount";
import Table from "../components/TableComponent";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import { SERVER } from "../config/axios";
import Loader from "../components/Loader";
import { handleAxiosError } from "../utils/errors";

interface Values {
    searchterm: string;
}

type HomePropsType = {
    getDebtor: Function;
    authReducer: any;
};

const Home = (props: HomePropsType) => {
    const {
        getDebtor,
        authReducer: { users },
    } = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [debtors, setDebtors] = useState<any>([]);
    const [stats, setStats] = useState<any>({});

    const filterRisk = useCallback(
        (risk: string) => {
            const debtorList = debtors.filter(
                (debtor: any) => debtor?.riskLevel === risk
            );

            console.log(debtorList, "debtorList");
            return debtorList?.length ?? 0;
        },
        [debtors]
    );

    useEffect(() => {
        filterRisk("");
    }, [filterRisk]);

    useEffect(() => {
        fetchStats();
        fetchData();
    }, []);

    const fetchStats = async () => {
        setLoading(true);
        await SERVER.get("/debtors/get-debtors-stat")
            .then(({ status, data }: any) => {
                if (status === 200) {
                    setStats(data?.data);
                }
            })
            .catch((error) => handleAxiosError(error))
            .finally(() => setLoading(false));
    };

    const fetchData = async () => {
        setLoading(true);
        await SERVER.get("/debtors")
            .then(async ({ status, data }: any) => {
                if (status === 200) {
                    setDebtors(data?.data);
                    // setIsLastPage(data?.isLastPage);
                    // setPages(data?.pages);
                }
            })
            .catch((error) => handleAxiosError(error))
            .finally(() => setLoading(false));
    };

    return (
        <>
            <DashboardLayout>
                <div className="grow">
                    <div className="p-5">
                        {/* Top Bar Start */}
                        <div className="w-full pt-8 flex items-center justify-between">
                            <div>
                                <Formik
                                    initialValues={{
                                        searchterm: "",
                                    }}
                                    onSubmit={(
                                        values: Values,
                                        { setSubmitting }: FormikHelpers<Values>
                                    ) => {
                                        setTimeout(() => {
                                            alert(
                                                JSON.stringify(values, null, 2)
                                            );
                                            setSubmitting(false);
                                        }, 500);
                                    }}
                                >
                                    <div className="flex items-center w-full">
                                        {/* <Form className="w-[654px]">
                                        <div className="flex items-center relative">
                                            <div className="grow">
                                                <FormElement
                                                    type="search"
                                                    name="searchItem"
                                                    labelFor="searchItem"
                                                    placeholder="Search"
                                                    className="grow"
                                                />
                                            </div>
                                            <img
                                                alt=""
                                                src={searchIcon}
                                                className="absolute right-4"
                                            />
                                        </div>
                                    </Form> */}
                                    </div>
                                </Formik>
                            </div>
                            <NavAccount />
                        </div>
                        {/* Top Bar End */}

                        {/* Overview Start */}
                        <div className="w-full mt-[72px] flex flex-col gap-y-16">
                            <div className="flex items-center justify-between">
                                <h1 className="font-semibold text-[32px]">
                                    Overview
                                </h1>

                                <div
                                    className="flex items-center gap-x-3"
                                    onClick={() => navigate("debtor-profiling")}
                                >
                                    <button className="w-[46px] h-[46px] border-2 border-b-4 border-r-4 border-primary bg-gray-dark rounded-full grid place-items-center hover:bg-gray-dark/50">
                                        <img alt="" src={plus} />
                                    </button>
                                    <p className="font-semibold text-base xl:text-lg">
                                        Add Debtor
                                    </p>
                                </div>
                            </div>

                            <div className="grid grid-cols-4">
                                <div className="text-center">
                                    <h2 className="text-6xl xl:text-[96px] font-bold">
                                        {stats?.totalDebtorsCount || 0}
                                    </h2>
                                    <p className="text-lg xl:text-xl">
                                        Total Debtors
                                    </p>
                                </div>
                                <div className="text-center">
                                    <h2 className="text-6xl xl:text-[96px] font-bold">
                                        {stats?.usersCount || 0}
                                    </h2>
                                    <p className="text-lg xl:text-xl">
                                        Total Upload
                                    </p>
                                </div>
                                <div className="text-center">
                                    <h2 className="text-6xl xl:text-[96px] font-bold text-[#C54B4B]">
                                        {stats?.highRiskDebtors || 0}
                                    </h2>
                                    <p className="text-lg xl:text-xl">
                                        High Risk Debtors
                                    </p>
                                </div>
                                <div className="text-center">
                                    <h2 className="text-6xl xl:text-[96px] font-bold text-[#2D8153]">
                                        {stats?.lowRiskDebtors || 0}
                                    </h2>
                                    <p className="text-lg xl:text-xl">
                                        Low Risk Debtors
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Debtors Table */}
                        <div className="pt-32">
                            <h3 className="text-2xl xl:text-[32px] font-semibold mb-8">
                                Debtors
                            </h3>

                            <Table type="DEBTORS" data={debtors.slice(0, 10)} />
                        </div>
                    </div>
                </div>
            </DashboardLayout>
            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = ({ authReducer }: any) => ({
    authReducer,
});

export default connect(mapStateToProps, actions)(Home);
