import axios from "axios";
import store from "../store";
import { toast } from "react-toastify";
import { logOut } from "../store/actions";
import { handleAxiosError } from "../utils/errors";

export const SERVER = axios.create({
    // baseURL: "http://localhost:8080/api/v1",
    baseURL: "https://api.baddebtorsportal.com/api/v1",
});

SERVER.interceptors.request.use(
    async (config: any) => {
        const { token } = store.getState().authReducer;

        if (token) {
            config.headers = {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
                ...config.headers,
            };

            return config;
        }
        return config;
    },
    (error) => {
        if (error?.response?.status === 401) {
            handleAxiosError(error)
        } else {
            handleAxiosError(error)
        }

        // store.dispatch(logOut())

        // return Promise.reject(error);
    }
);
