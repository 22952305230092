import { Field, useField } from "formik";
import React from "react";
import eye from "../assets/icons/eye.svg";
import { Link } from "react-router-dom";
import { error } from "console";

// interface _props {
//     type?: "select" | "text" | "password" | "email";
//     isPassword?: boolean;
// }

const Input = (props: any) => {
    return (
        <Field
            {...props}
            className={`h-10 xl:h-14 w-full rounded-lg border-2 px-4 mt-1 placeholder:text-primary/40 ${
                props.className
            } ${props.error ? "border-[#FF011E]" : "border-primary"}`}
        />
    );
};

const PasswordInput = (props: any) => {
    const [show, setShow] = React.useState(false);
    return (
        <div className={`relative flex items-center`}>
            <Field
                id={props.id}
                name={props.name}
                type={show ? "text" : "password"}
                placeholder={props.placeholder}
                className={`h-10 xl:h-14 w-full rounded-lg border-2 px-4 mt-1 placeholder:text-primary/40 ${
                    props.className
                } ${props.error ? "border-[#FF011E]" : "border-primary"}`}
            />
            <img
                alt=""
                src={eye}
                className="absolute right-4 pt-1 hover:cursor-pointer"
                onClick={() => setShow(!show)}
            />
        </div>
    );
};

const Select = (props: any) => {
    // const { field } = useField;
    return (
        <select
            className={`h-10 xl:h-14 rounded-lg border-2 px-4 mt-1 placeholder:text-primary/40 ${
                props.className
            } ${props.error ? "border-[#FF011E]" : "border-primary"}`}
            {...props}
            onChange={(e) => props?.onClick(e)}
        >
            {" "}
            <option disabled selected>
                Select one
            </option>
            {props.options?.map((option: any, index: number) => (
                <option key={index} value={option}>
                    {option}
                </option>
            ))}
        </select>
        // <Field
        //     {...props}
        //     type="select"
        //     className="h-14 w-full rounded-lg border-2 border-primary px-4 mt-1 placeholder:text-primary/40"
        // />
    );
};

const TextArea = (props: any) => {
    return (
        <textarea
            value={props?.value}
            onChange={(e) => props?.onClick(e)}
            className={`h-[200px] xl:h-[264px] w-full rounded-lg border-2 p-4 mt-1 placeholder:text-primary/40 ${
                props.error ? "border-[#FF011E]" : "border-primary"
            }`}
            {...props}
        ></textarea>
    );
};

const FormElement = (props: any) => {
    return (
        <div className="flex flex-col">
            {props.label && (
                <label
                    htmlFor={props.labelFor}
                    className="text-xl font-semibold"
                >
                    {props.label}
                </label>
            )}
            {props.type === "password" || props.isPassword ? (
                <PasswordInput {...props} />
            ) : props.type === "select" ? (
                <Select {...props} />
            ) : props.type === "textarea" ? (
                <TextArea {...props} />
            ) : props.pure ? (
                <input
                    {...props}
                    className={`h-10 xl:h-14 w-full rounded-lg border-2 px-4 mt-1 placeholder:text-primary/40 ${
                        props.className
                    } ${props.error ? "border-[#FF011E]" : "border-primary"}`}
                />
            ) : (
                <Input
                    {...props}
                    className={props.error ? "border-[#FF011E]" : ""}
                />
            )}
            {props.isAuth && (
                <Link
                    to="/forgot-password"
                    className="text-right text-primary/60 mt-2"
                >
                    Forgot Password
                </Link>
            )}
        </div>
    );
};

export default FormElement;
